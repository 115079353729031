import Vue from 'vue'
import Vuex from 'vuex'
import billStore from './modules/bill';
import inOutReport from './modules/inoutreport.js';
import customerSupport from './modules/customerSupport';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    daysList: [
      {text: '1', value: '1'},
      {text: '2', value: '2'},
      {text: '3', value: '3'},
      {text: '4', value: '4'},
      {text: '5', value: '5'},
      {text: '6', value: '6'},
      {text: '7', value: '7'},
      {text: '8', value: '8'},
      {text: '9', value: '9'},
      {text: '10', value: '10'},
      {text: '12', value: '12'},
      {text: '13', value: '13'},
      {text: '14', value: '14'},
      {text: '15', value: '15'},
      {text: '16', value: '16'},
      {text: '17', value: '17'},
      {text: '18', value: '18'},
      {text: '19', value: '19'},
      {text: '20', value: '20'},
      {text: '21', value: '21'},
      {text: '22', value: '22'},
      {text: '23', value: '23'},
      {text: '24', value: '24'},
      {text: '25', value: '25'},
      {text: '26', value: '26'},
      {text: '27', value: '27'},
      {text: '28', value: '28'},
      {text: '29', value: '29'},
      {text: '30', value: '30'},
      {text: '331', value: '331'},
    ],
    monthsList: [
      {text: '1', value: '1'},
      {text: '2', value: '2'},
      {text: '3', value: '3'},
      {text: '4', value: '4'},
      {text: '5', value: '5'},
      {text: '6', value: '6'},
      {text: '7', value: '7'},
      {text: '8', value: '8'},
      {text: '9', value: '9'},
      {text: '10', value: '10'},
      {text: '12', value: '12'}
    ],
    yearList: [
      {text: '2021', value: '2021'},
      {text: '2022', value: '2022'},
      {text: '2023', value: '2023'},
      {text: '2024', value: '2024'},
      {text: '2025', value: '2025'},
      {text: '2026', value: '2026'},
    ],
    plateChars: [
        {text: '', value: ''},
        {text: 'أ - A', value: 'A'},
        {text: 'ب - B', value: 'B'},
        {text: 'ح - J', value: 'J'},
        {text: 'د - D', value: 'D'},
        {text: 'ر - R', value: 'R'},
        {text: 'س - S', value: 'S'},
        {text: 'ص - X', value: 'X'},
        {text: 'ط - T', value: 'T'},
        {text: 'ع - E', value: 'E'},
        {text: 'ق - G', value: 'G'},
        {text: 'ك - K', value: 'K'},
        {text: 'ل - L', value: 'L'},
        {text: 'م - Z', value: 'Z'},
        {text: 'ن - N', value: 'N'},
        {text: 'ه - H', value: 'H'},
        {text: 'و - U', value: 'U'},
        {text: 'ى - V', value: 'V'},
    ],
    showLoading: false,
    showFirst: true,
    showMessage: true,
    customers: [],
    COOKIEPhase: 'SAMCCCC-Cookies',
    LangCooki: 'SAMCLANG-Cookies',
    SAMCOTEC: {
      r_path: '/api/index.php', 
      p_path: '/api/export-print.php',
      e_path: '../api/excel.php', 
      pd_path: '../api/pdf.php',
    },
    settings: {
      dev_vat: 15,
    },
    currentMenu: "CurrentMenu",
    vcurrentMenu: "VCurrentMenu",
    currentlink: "currentlink",
    userinfo: [],
    lang:{
      en: {
        langname: 'en',
        dir: 'ltr',
        ldir: 'rtl',
        algin: 'left',
        lalgin: 'right',
        invoiceno: 'Invoice No.',
        plate_number: 'Plate No.',
        main_menu: 'Main Menu',
        search: 'Search',
        invoices: 'Invoices',
        add_invoice: 'Add invoice',
        invtype: 'Invoice Type',
        customer: 'Customer',
        mobile: 'Mobile',
        plate: 'Plate',
        model: 'Model',
        inv_date: 'Date',
        total: 'Total',
        vat: 'VAT',
        ftotal: 'Total inc. VAT',
        paid: 'Paid',
        remain: 'Remain',
        remain_amount: 'Remianing Amount',
        action: 'Action',
        status: 'Status',
        view: 'View',
        totals: 'Totals',
        close: 'Close',
        customer_name: 'Customer Name',
        customer_type: 'Customer Type',
        company_name: 'Company name',
        comapny_address: 'Company address',
        company_vatid: 'VAT Number',
        car_model: 'Model',
        car_year: 'Year made',
        car_color: 'Color',
        invoice_type: 'Invoice type',
        vat_type: 'VAT type',
        payment_method: 'Payment method',
        saler_name: 'Saler name',
        invoice_date: 'Invoice date',
        invoice_details: 'Invoice details',
        item_code: 'Item code',
        description: 'Description',
        qtty: 'QTTY',
        item_price: 'Item price',
        discount: 'Discount',
        create_invoice: 'Create Invoice',
        cash_payment: 'Cash method',
        span_payment: 'Span method',
        add: 'Add',
        individual_client: 'Individual  ',
        company_clinet: 'Comp. client',
        prices_include_vat: 'Prices include vat',
        prices_exclusive_vat: 'Prices exclusive of vat',
        prices_without_vat: 'Without VAT',
        none_paid_invoice: 'None Paid Invoice',
        paid_invoice: 'Paid Invoice',
        bank_payment: 'Bank Transfer',
        multi_payments: 'Multi payment method',
        required_field: 'This field is required',
        you_can_not_add_empty_invoice: 'Can not add empty invoice',
        company_ifnormation_required: 'Company information required - Name, Address, CR Numebr',
        invoice_added: 'Invoce Added',
        can_not_add_item_withoud_discription: 'Can not add item without description',
        item_qtty_must_be_more_than_zero: 'Can not add item with zero QTY',
        item_price_must_be_more_than_zero: 'Can not add item with zero price',
        print_invoice: 'Print Invoice',
        view_pdf: 'View PDF',
        invoice_return: 'Return Invoice',
        make_payment: 'Make a payment',
        invoice_tme: 'Invoice Time',
        return_date: 'Return Date',
        return_time: 'Return time',
        return_case: 'Return resone',
        total_exclusive_vat: 'Total exclusive vat',
        total_include_vat: 'Total include VAT',
        payments: 'Payments',
        payment_date: 'Payment date',
        payment_time: 'Payment time',
        payment_amount: 'Payment amount',
        payment_cash: 'Payment cash',
        payment_span: 'Payment Span',
        payment_bank: 'Payment Bank',
        return_invoice: 'Return Invoice',
        return_amount: 'Return amount',
        return: 'Return',
        system_modules: 'Modules',
        customers: 'Customers',
        quotations: 'Quotations',
        accounting: 'Accounting',
        envintory: 'Envintory',
        reports: 'Reports',
        settings: 'Settings',
        logout: 'Logout',
        invoice_notes: 'Invoice Notes',
        add_customer: 'Add Customer',
        additional_mobile: 'Additional Number',
        client_statement: 'Statements',
        mobil_10_digits: 'Mobile No. Must be 10 digits',
        customer_added: 'Customer Added sucsessfuly',
        add_quotation: 'Add Quotation',
        quotnumber: 'Quot No.',
        salesman: 'Salesman',
        quot_date: 'Quotation date',
        quotation_details: 'Quot Details',
        delete: 'Delete',
        company_client: 'Company Client',
        can_not_add_empty_row: 'Can not add empty row',
        please_add_company_info: 'Please add company information',
        quotation_added: 'Quotatin added',
        can_not_add_row_without_discription: 'Can not add row without discription',
        can_not_add_row_with_qtty: 'Please add qtty more than 0',
        date: 'Date',
        credit: 'Credit',
        depit: 'Depit',
        new_quotations: 'New quotations',
        invoiced_quots: 'Invoiced Quots',
        canceled_quots: 'Canceled Quots',
        quotation_no: 'Quot No.',
        printQuot: 'Print Quot.',
        cancel_quto: 'Cancel Quot.',
        quot_time: 'Quot Time',
        print: 'Print',
        cash_total: 'Cash Total',
        span_total: 'SPAN total',
        voutcher_no: 'Voutcher No.',
        receive_from: 'Receive From',
        receive_by: 'Receive By',
        cash: 'Cash',
        span: 'SPAN',
        received_voucher: 'Catch Receipt',
        exchange_voucher: 'Exch. voucher',
        employees: 'Employees',
        recurring_expensess: 'Recurring Expensess',
        add_voutchre: 'Add Voutcher',
        voutcher_resone: 'Voutcher Resone',
        emp_name: 'Employee name',
        id_number: 'ID number',
        basic_salary: 'Basic Salary',
        home_allownance: 'Home Allownance',
        trans_allownance: 'Transport Allownance',
        food_allown: 'Food Allownance',
        other_allown: 'Other Allownance',
        goze_perc: 'GOZE',
        percentate: 'Percentage',
        update: 'Update',
        add_employee: 'Add employee',
        add_expenses: 'Add Expenses',
        expense_type: 'Expense Type',
        yearly: 'Yearly',
        midterm: 'Midterm',
        quarterly: 'Quarterly',
        monthly: 'Monthly',
        daily: 'Daily',
        expense_total: 'Expense Total',
        expense_period: 'Expense Period',
        calculation_will_be_done_automatic: 'Calculation values will be done automaticlly',
        office_rent: 'Office Rent',
        add_maint_request: 'Add Job Order',
        cardid: 'Card No.',
        received: 'Received',
        delivered: 'Delivered',
        spare_partes: 'Spare Parts',
        spare_parts: 'Spare Parts',
        main_info: 'Main info',
        received_date: 'Received date',
        delivery_date: 'Delivery date',
        customer_info: 'Customer Info',
        company_info: 'Company Info',
        car_info: 'Car Info',
        received_status: 'Received Status',
        work_description: 'Work Description',
        worker: "Worker",
        work_price: 'Work Price',
        advanced_payment: 'Advanced payment',
        attachement: 'Attatchment',
        choose_file: 'Choose File',
        save: 'Save',
        additional_information: 'Additional Information',
        work_description_required: 'Work Description Required',
        added_sucess: 'Added Success',
        add_user: 'Add user',
        update_user: 'update user',
        users: 'Users',
        user_name: 'Full name',
        login_name: 'Username',
        permission: 'Permissions',
        last_login: 'Last Login',
        save_login_info: 'Save Login Info',
        password: 'Password',
        export_excel: 'Export excel',
        add_purcahse: 'Add purcahse',
        vendor: 'Vendor',
        purchase: 'Purchase',
        inventory: 'Inventory',
        item_name: 'Item name',
        sales_invoices: 'Sales Invocies',
        sales: 'Sales',
        vat_required_return: 'VAT Required (Return)',
        vat_report: 'VAT Report',
        dealy_report: 'Dealy report',
        card_report: 'Card Report',
        sales_report: 'Sales Report',
        return_report: 'Return report',
        percentage: 'Percentage',
        income: 'Income',
        expenses: 'Expenses',
        expense: 'Expense',
        period_expenses: 'Period Expenses',
        closed_by: 'Closed By',
        return_by: 'Return By',
        shortcust_name: 'Shortcut name',
        vat_number: 'VAT Number',
        system_settings: 'System Settings',
        welcometo: 'Welcome',
        view_invoice  : 'View Invoice',
        added_date: 'Added Date',
        added_time: 'Added Time',
        photo_gallary: 'Photo Gallary',
        print_card: 'Print Card',
        car_description: 'Car description',
        invoice_samary: 'Invoice Summary',
        others: 'Others',
        view_card: 'View Card',
        card_closed: 'Card closed',
        please_check_data: 'Please check the data',
        cant_close_card_when_it_not_fully_paid: 'Please first make all remaining payment',
        card_saved: 'Card Saved',
        customerid: 'Customer ID',
        purchase_invoice: 'Purchase Bills',
        billno: 'Bill No',
        vendor_name: 'Vendor Name',
        vendor_mobile: 'Vendor Contact No.',
        vendor_vatids: 'Vendor VAT No.',
        vendor_address: 'Vendor Address',
        type: 'Type',
        bill_type: 'Bill Type',
        bill_detates: 'Bill Details',
        item_unit: 'Item Unit',
        pace: 'Pace',
        view_purchase_bill: 'View Purchase Bill',
        received_amount: 'Received Amount',
        close_card: 'Close Card',
        chose_file: 'Choose your file',
        upload: 'Upload',
        full_name: 'Full name',
        total_amount: 'Total Amount',
        buy_goods: 'Buy goods',
        sallary: 'Sallary',
        buy_devices: 'Buy devices',
        pay_for_bills: 'Pay bills',
        added_successfuly: 'Successfuly Added',
        nationality: 'Nationality',
        percent: 'Percent',
        group_name: 'Group name',
        permissions: 'Permissions',
        administratior: 'Administrator',
        full_permissions: 'Full permissions',
        accountant: 'Accountant',
        accountatn_permissions: 'Full permissions without setting',
        cashier: 'Cashier',
        cashier_permissions: 'Invoice system and generate cards',
        choose_employee: 'Choose employee',
        projectname: 'Project Name',
        inprogress: 'Progress',
        closed: 'Closed',
        not_invoiced: 'Un Billed',
        vendorid: 'Vendor ID',
        paid_in_advanced: 'Advanced Payment',
        paid_in_advanced_amount: 'Amount',
        installment_number: 'Installment #',
        installment_amount: 'Installment Amount',
        mor_than_zero: 'Please add number more than zero',
        emp_reward: 'Rewarded',
        reward_amount: 'Reward Amount',
        reward_resone: 'Reward resone',
        emp_deducts: 'Deducts',
        deducts_amount: 'Deduct Amount',
        deducts_resone: 'Deduct Resone',
        canceled: 'Canceled',
        notpaid: 'Not Paid',
        workstartdate: 'Work Start Date',
        fix_salary: 'Salary Issue',
        card_expenses: 'Maint. Expenses from inventory',
        invoice_expenses: 'Invoice Expenses',
        expenses_summary: 'Expenses Summary',
        general_expenses: 'General Expenses',
        cards_expenses: 'Maintenance',
        invoices_expenses: 'Invoices',
        salaries: 'Salaries',
        add_general_expense: 'Add Expense',
        periodically: 'Periodically',
        payit: 'Pay Now',
        pay_expenses: 'Pay Expenses',
        payment_period: 'Payment Period',
        expense_amount: 'Amount',
        othermethod: "Other methods.", 
        invoices_total: 'Invoice Totals', 
        invoices_remain: 'Invoice Remaining', 
        other_expenses: 'Other Exp.', 
        exp_total: 'Exp. total', 
        exp_vat:'Exp. VAT',
        employees_and_users: 'ُEmpoyees',
        building_no: 'Building No.',
        street_name: 'Street Name',
        branch_number: 'Branch No.',
        district: 'District',
        zipcode: 'Zipcode',
        city:'City',
        address: 'Address',
        update_customer: 'Update Customer',
        customer_updated: 'Customer Updated',
        update_all: 'Update all',
        update_all_notes: 'تعديل الكل : تعديل كل الفواتير او سندات القبض بالبيانات الجديدة',
        curency: 'SAR',
        pos: 'Point of Sale',
        cancel: 'Cancel',
        total_after_discount: 'Total After Discount',
        multi_payment: 'Multi Payment Methods',
        paid_amount: 'Paid Amount',
        category_name: 'Category',
        category_name_ar: 'Name Ar',
        category_name_en: 'Name En',
        category_type: 'Cat. Type',
        category_parent: 'Parent',
        subcat_count: 'Sub cats.',
        products: 'Items',
        add_category: 'Add Category',
        main_cat: 'Main Category',
        sub_cat: 'Sub Category',
        income_report: 'Income Report',
        incomes: 'Incomes',
        amountprice: 'Amount',
        itotals_per_paymethod: 'Income Totals per Payment Method',
        ototals_per_paymethod: 'Outcome Totals per Payment Method',
        account_statement: 'Account Statment',
        balance: 'balance',
        accounts: 'Accounts',
        account_balance_report: 'Balance Report',
        openingbalance: 'Opining Balance',
        transaction: 'Transaction Balance',
        account: 'Account',
        vlaue: 'Value',
        chart_of_account: 'Chart Of Account',
        definition: 'Definitions',
        paytypes: 'Payment Method',
        car_report: 'Car Report',
        amounttotal: 'Amount Total',
        namear: 'Arabic Name',
        nameen: 'English Name',
        classid: 'Account No.',
        add_pay_type:'Add payment Type',
        statment: 'Statement',
        class_name: 'Account name',
        fixed_assets: 'Fixed Assets',
        late: 'Late',
        price: 'Price',
        vendors: 'Vendors',
        invoice_total: 'Invoices Total',
        vat_total: 'VAT Total',
        add_vendor: 'Add vendor',
        update_vendor: 'Update Vendor',
        vendor_updated_successufly: 'Vendor Added/Updated successfuly',
        vendor_name_required: 'Vendor Name Required',
        vendor_mobile_required: 'Vendor Mobile Required',
        finance_jvs: 'J-Voucher',
        jvnom: 'JV #',
        notes: 'Notes',
        addJV: 'Add JV',
        bnl_report: 'PNL Report',
        balance_report: 'Balance Report',
        gozi_report: 'GOZI Report',
        sales_crm: 'Sales',
        exp_management: 'Management Exp.',
        maintenance_cards: 'Maintenance Cards',
        customer_info_required: 'Customer name/mobile required',
        delivery_date_required: 'Delivery Date Required',
        car_info_required: 'Car info Required',
        please_choose_worker: 'Please choose worker',
        job_price_required: 'Job price required',
        customer_addedd: 'Custoemr Added',
        new: 'New',
        view_employee: 'View Employee',
        add_new_account: 'Add new account',
        account_name_ar: 'Arabic name',
        account_name_en: 'English name',
        mainaccount: 'Main Account',
        accoutn_option: 'Account Options',
        update_acount: 'Update Account',
        acount_name: 'Account name',
        account_type: 'Account Type',
        default_type: 'Default Type',
        account_integrated: 'Integrated with',
        integrated_with_expenses: 'Expenses Integrated',
        account_name: 'Account name',
        expeneses_type: 'Expenses type',
        projects: 'Projects',
        add_project: 'Add Project',
        additional_method: 'Additional Discound',
        adding_date: 'Adding Date',
        ammount: 'Amount',
        method: 'Method',
        home: 'Home',
        payment_requests:'Payments',
        requests:'Requests',
        ment_req_cards_new: 'Cards Maint. New',
        ment_req_cards_late: 'Cards Maint. Late',
        ment_req_cards_closed: 'Cards Maint. Closed',
        print_pagexport_pdfe: 'Export PDF',
        print_page: 'Print Page',
        sent_requests: 'Sents Requests',
        item_cost: 'Item Cost',
        username: 'Username',
        welcome: "Welcome",
        add_product: 'Add Product',
        orders: 'Orders',
        booktime: 'Book Time',
        engine_meter: 'Car meter reading (KM)',
        next_oil_change: 'Change oile after (KM)',
        crt_number: 'CR Number',
        company_name_en: 'Company English Name',
        contact_number: 'Contact number',
        you_cannot_delete_this_item_paid_more_than_total: 'You cannot delete this item, total of this card is less than total paid',
        // vin_numebr: 'VIN Number',
        worker_name: 'Worker Name',
        start_date: 'Start date',
        end_date: 'End date',
        total_invoices: 'Total Invoices',
        total_remain: 'Total Remain',
        sendWhats: 'Send WhatsApp',
        salary_report: 'Salary report',
        percent_report: 'Percent report',
        cards_number: 'Cards Number',
        catd_totals: 'Cards Total',
        totalPercent: 'Total Percent',
        cards_expsenses: 'Cards Expenses',
        convert_to_invoice: 'Invoice Quot.',
        chose_month: 'Chose Month',
        year: 'Year',
        logo_img: 'Logo Image',
        terms_of_use: 'Terms of use',
        tafweed: 'Terms of use',
        expense_namear: 'Expenses Type Arabic Name',
        expense_nameen: 'Expenses Type English Name',
        expenses_type_updated: 'expenses type updated',
        add_new_expense_type: 'Add new expense type',
        expenses_type_added: 'expenses type added',
        income_expense_report: 'Income & Expense Report',
        other: 'other',
        expnese: 'Expenses',
        old_balance: 'Old Blance',
        purchase_bill: 'Purchase Bill',
        purchase_summary: 'Purchase summary',
        print_voucher: 'Print',
        sales_vat: 'Sales VAT',
        bill_vat: 'Purchase VAT',
        invoice_export: 'Create Invoice',
        add_expense_type: 'Add Expense Type',
        name_ar_en_required: 'Arabic/English type name required',
        add_direct_invoice: 'Add direct invoice',
        car_ifnormation_required: 'Car information required',
        save_n_print: 'Save And Print',
        print_in_voucher: 'Print',
        chose_customer_type: 'Chose Custoemr Type',
        chose_invoice_type: 'Chose Invoice Type',
        chose_vat_type: 'Chose VAT Type',
        chose_payment_method: 'Chose Payment Type',
        chose_saler_name: 'Chose Saler name',
        total_without_vat: 'Total Ex. VAT',
        fftotal: 'Total inc. VAT', 
        changePassword: 'Change Password',
        newpassword: 'New Password',
        oldpassword: 'Old Password',
        printing_header: 'Printing Header',
        invoice_body: 'Invoice Body',
        voucher_body: 'Voucher Body',
        card_body: 'Maint Card Body',
        printing_footer: 'Printing Footer',
        car_status_receive: 'Car receive status',
        add_attachement: 'Add Attachments',
        government_clinet: 'Government',
        view_page: 'Page Preview',
        return_invoices: 'Return Invocies',
        vat_summary: 'VAT Summary',
        // fftotal: 'Total Inc. VAT',
        add_edit_product: 'Add/Edit Product',
        current_qtty: 'Current Qtty',
        price_without_vat: 'Price Ex. VAT',
        price_inc_vat: 'Price Inc. VAT',
        default_cost: 'Default Cost',
        added_value_tax: 'Enable Added Value Tax',
        product_category: 'Product Category',
        product_type: 'Product Type',
        inventory_item: 'Inventory Item',
        service_item: 'Service Item',
        this_item_service_item: 'Only inventory item type can be add to purchase bill',
        day: 'Day',
        print_out_voucher: 'Print Voucher',
        expenses_type_reoprt: 'Expenses Type Report',
        view_quotation: 'View Quotation',
        view_expenses: 'View Expenses Invoice',
        customer_company_info_required: 'Company Name and address are required',
        for_more_information_or_support_firstline: 'For support, or any inquires please contat us',
        for_more_information_or_support_secondline: '0566566160 - 0595907777 - 0565100268',
        whatsapp: 'WhatsApp',
        add_invoice_notes: 'Add notes to Invoice (warranty - Terms, etc.)', 
        new_customer: 'New Customer',
        entity_name: 'Entity Name',
        branch_name: 'Branch Name',
        request_number: 'Requist Number',
        order_number: 'Order Number',
        po_number: 'Request Number',
        po_date: 'Request Date',
        add_car: 'Add new car',
        footer_extra: "Footer Extend",
        view_quot_pdf: 'Download ',
        bid_number: 'Bid Number',
        expenses_invoices: 'Expenses Invocie',
        chose_car: 'Chose a Car',
        total_b_d: 'Total Befor Discount',
        total_discount: 'Discount',
        cars_list: 'Cars List',
        out_payments: 'Out Voutcher',
        in_payments: 'In Voutcher',
        changeNote: 'Modify Note',
        are_you_sure: 'Are you sure?',
        add_invocie: 'Add invoice',
        add_new_card: 'Add Job Order',
        cars_status: 'Cars Status',
        reset: 'Reset',
        undo: 'Undo',
        chose_customer: 'Chose Customer',
        chose_company: 'Chose Company',
        chose_entity: 'Chose Entity',
        company_name_required: 'Company name required',
        customer_name_required: 'Customer name required',
        company_address_required: 'Company address required',
        entity_name_required: 'Entity name required',
        car_information_must_be_complated: 'Car information for car plate {{plate_number}} required',
        you_must_add_one_car_atleast: 'You must add one car at least',
        you_must_add_atleast_one_work_item: 'You must add one item to work job',
        plase_select_car_scratch: 'You can not add empty image',
        chose_car_before_continue: 'You must select car to continue',
        approve_card: 'Approve Card',
        isbilled: 'is Billed',
        complete: 'Complete',
        add_new_work_expenses: 'Add new work/Expense',
        row_type: 'Row Type',
        agree: 'Agree',
        details: 'Details',
        time: 'Time',
        payment_type: 'Payment Type',
        amount: 'Amount',
        debet: 'Debet',
        daily_summary_for_date: 'Daily Summary for date',
        today_balance: 'Day\' Balance',
        previews_balance: 'Previews Balance',
        total_balance: 'Total Balance',
        purchase_and_expenses:'Purchase & Expese',
        credit_note:'Credit Notes',
        period_balance: 'Period Balance',
        add_vendor_voucher: 'Exchange Vouch.',
        salary_paid: 'Salary Payment',
        client_statment: 'Client Statement',
        file_size: 'File size',
        file_name: 'File name',
        download: 'Download',
        cancel_order: 'Cancel Order',
        add_order: 'Add order',
        view_order: 'View order',
        orderid: 'Order ID',
        order_date: 'Order date',
        order_time: 'Order Time',
        order_details: 'Order Details',
        order_items: 'Order Items',
        cancel_qtty: 'Canceled Qtty',
        cancel_reson: 'Canceled Reson',
        full_cancel_reson: 'Full cancel resone',
        full_cancel: 'Full Cancel',
        partial_cancel: 'Partial cancel',
        custody: 'Custody',
        return_custody: 'Paid From Custody',
        remain_custody: 'Remain From Custody',
        add_custody: 'Add Custody',
        width: 'Width',
        thickness: 'Thickness',
        limited: 'Limited',
        customer_sign: 'Signature',
        back: 'Back',
        terms_and_conditions: 'Terms and conditions',
        work_hours: 'Working hours',
        vendor_payments: 'Vendor Payments',
        terms: 'Terms',
        pay_from_custdy: 'Pay from custdy',
        pay_from_cash: 'Pay from cash',
        emp_statment: 'Employee Statement',
        deduct_from_salary: 'Salary Deduct',
        pay_advanced: 'Pay Advanced',
        add_reward: 'Add rewared',
        post_salary: 'Post Salary',
        indemnity: 'Indemnity',
        posted_salary: 'Posted Salary',
        card_percentage:'Card Percentage',
        invoice_percentage: 'Invoice Percentage',
        remain_salary: 'Remain Salary',
        lated_salaries: 'Rest Salary',
        salary_total: 'Salary Total',
        custdy: 'Custdy',
        mentanace_card: 'Mentanance Cards',
        service: 'Services',
        deduct: 'deduct',
        paid_in_advanced_total: 'Paid Advanced Ttoal',
        number_of_parts: 'Number of Parts',
        part_amount: 'Part Amount',
        returned: 'Retrurend',
        deduct_resone: 'Deduct Resone',
        minute_late: 'Minute Late',
        deduct_type: 'Deduct type',
        monthly_late: 'Monthly late',
        deduct_amount: 'Deduct Amount',
        monthly_deduct: 'Deduct Monthly Amount',
        fix_it: 'Fix',
        today_date: 'Today Date',
        search_by_name: 'Search By Name',
        daily_report: 'Daily Report',
        max_items_sales: 'Most Sales',
        less_items_sates: 'Less Sales',
        zero_items: 'Zero Items',
        sales_qtty: 'Sales QTY',
        total_income: 'Total Income',
        current_qty: 'Current QTY',
        remainpso: 'Remain',
        open_shift: 'Sift Start',
        _total_1_ryals: '1 SAR',
        _total_5_ryals: '5 SAR',
        _total_10_ryals: '10 SAR',
        _total_20_ryals: '20 SAR',
        _total_50_ryals: '50 SAR',
        _total_100_ryals: '100 SAR',
        _total_500_ryals: '500 SAR',
        opening_balance: 'Opening Balance',
        open_new_shift: 'Open shift',
        calendar: 'Calendar',
        add_purchase: 'Add Purchase',
        clode_shift: 'Close Shift',
        total_payment_to_vendor: 'Remain vendor payments is: ',
        total_payment_to_Client: 'Remain Client payments is: ',
        client_statement_pos: 'Client Statement',
        client_appointment: 'Client Calendar',
        add_client_voucher: 'Add Client Payment',
        invoice_summary: 'Invoice Summary',
        total_after_discountt: 'Price After Discount',
        cashair: 'Cashair',
        managment: 'Management',
        smiller_products:'Smiller products',
        emp_custody: 'Custody',
        menu: 'Mentu',
        book_tables: 'Book Table',
        add_your_cash: 'Add your shift cash',
        mobile_info_required: 'Client mobile required and must start with 05 and more than 5 digits',
        name_info_required: 'Client name required and must more than 5 chars',
        view_customer: 'View Customer',
        add_fincance_request: 'Add F-Request',
        cars: 'Cars',
        add_notes: 'Add Notes',
        finance_report: 'Finance Report',
        unpaid_job_orders: 'Not Invoiced',
        report: 'Report',
        financial_request: 'Financial Requests',
        tasks: 'Tasks',
        add_task: 'Add Task',
        task_description: 'Task Description',
        dead_line: 'Dead Line',
        task_to: 'Task to User',
        task_description_required: 'Task description required',
        task_start_end_date_required: 'Task start & deadline required',
        start_time_required: 'Calendar start time & deadline time required',
        last_update_by: 'Updated By',
        last_update_date: 'Updated Date',
        closed_date: 'Closed Date',
        add_warantly: 'Warantly Job',
        plate_number_required: 'Plate number required',
        add_booking: 'Booking',
        start_time: 'Start time',
        deadline_time: 'Deadline time',
        book_calendar: 'Calendar',
        tasks_status: 'Tasks',
        warantly_status: 'Waratnly',
        calendar_status: 'Calendar',
        customer_status: 'Customer Stauts',
        ment_description: "Ment. Description",
        add_new_spare_part: 'Add Spare part',
        // item_name: 'Spare part name',
        spare_part_item_code: 'Spare part item code',
        add_spare_part: 'Add sparepart',
        work_type: 'Work Type',
        carsnumber: 'Cars Count',
        add_new_status: 'Add New Status',
        vin_numebr: 'VIN',
        brand_name: 'Car Make',
        ready_to_deliver: 'Ready',
        cancel_card: 'Cancel',
        vin_number: 'VIN',
        calendar_table: 'Calendar Table',
        choose_custoemr: 'Select Client',
        estimated_price: 'Estimated Price',
        work_name: 'Work Name',
        booking_procedure: 'Booking procedure',
        change_booking_date: 'Change booking',
        edit_calendar: 'Edit Calendar',
        expire_date: 'Expire date',
        note_date: 'Note date',
        added_by: 'Added by',
        task_type: 'task Type',
        training: 'Training',
        job_order: 'Job Order',
        last_update: 'Last update',
        last_action: 'Last Action',
        next_action: 'Next Action',
        next_action_date: 'Action Date',
        agent: 'Agent',
        view_crm: 'View CRM',
        generate_license: 'Gen. License',
        note_text: 'Note Text',
        note_type: 'Note Type',
        task_response: 'Response',
        license_url: 'License URL',
        copies: 'Number of License',
        include_computers: 'Include Computers',
        yes_19: 'Yes include 19 Inch',
        yes_24: 'Yes include 24 Inch',
        no_devices: 'No',
        last_action_date: 'Last Action Date',
        whats_type: 'Whats MSG type',
        whats_url_multi: 'Doc, Image, Vedio URL',
        update_task: 'Update Task',
        industrial: 'Industrial',
        support_ticket: 'Support',
        add_support: 'Add Support',
        send_date: 'Sent date',
        message_type: 'Message Type',
        message_text: 'Message Text',
        customer_number: 'Customers Number',
        sending_number: 'Sending Number',
        media_link: 'Media Link',
        send_whatsapp: 'Send WhatsApp',
        send_to: 'Sent To',
        sent_type: 'Sent Type',
        number_list: 'Numbers List',
        number_list_notes: 'Separate numbers with comma',
        image_url: 'Image URL',
        document_url: 'Document URL',
        vedio_url: 'Vedio URL',
        message_title: 'Subject',
        client_type: 'Client Type',
        cannot_delete_this_customer_invoices_found: 'This customer had Invoices you can not delete it',
        cannot_delete_this_customer_cards_found: 'This customer had JOB CARDS you can not delete it',
        custoemr_delete_done: 'Customer Deleted Successfuly',
        cannot_delete_this_vendor_invoices_found: 'This vendor had Invoices you can not delete it',
        cannot_delete_this_vendor_payment_found: 'This vendor had Payments you can not delete it',
        vendor_delete_done: 'Vendor Deleted Successfuly',
        employee_deleted: 'Employee Deleted',
        advanced_payment_deleted: 'Advanced Payment Deleted',
        employee_name: 'Employee Name',
        number_of_tasks: 'Tasks #',
        closed_tasks: 'Closed',
        canceled_tasks: 'Canceled',
        opened_tasks: 'Open',
        add_work_order: 'Add Work Order',
        owner_name: 'Car Owner Name',
        companies: 'Companies',
        exeute_date: 'Execute Date',
        task_priority: 'Task Priority',
        day_name: 'Day Name',
        industry: 'Industry',
        support: 'Support',
        project_type: 'Project Type',
        project_title: 'Project Title',
        project_owner: 'Project Owner',
        project_start: 'Project Start',
        project_deadline: 'Project Start',
        project_close_date: 'Project Closed',
        project_employees: 'Project Employees',
        employee: 'Employees',
        task: 'Task',
        task_title: 'Task Title',
        checklist: 'Check List',
        superVisor: 'Supervisor',
        super_visor_required: 'Supervisor required',
        mobile_required: 'Mobile required',
        new_sales: 'New Sales',
        return_sales: 'Return Sales',
        card_type: 'Card Type',
        add_store: 'Add Store',
        store_name_ar: 'Store Name AR',
        store_name_en: 'Store Name EN',
        account_number: 'Account Number',
        trans_from: 'Transfer From',
        trans_to: 'Transfer To',
        trans_type: 'Transfer Type',
        received_by: 'Received By',
        add_transfer: 'Add Transfer',
        add_sinventory: 'Add Inventory',
        // inventory_item: 'Add Inventory',
        start_qtty: 'Start QTTY',
        cost_price: 'Cost Price',
        add_contract: 'Add Contract',
        contract_id: 'Contract ID',
        last_maint_date: 'Last Maint Date',
        last_maint_by: 'Last Maint. By',
        cars_number: 'Cars Number',
        contract_date: 'Contract Date',
        contract_expire_date: 'Contract Expired On',
        contract_start_date: 'Contract Start Date',
        meter_car: 'Car Meter',
        supports: 'Supports',
        contracts: 'Contracts',
        job_cards: 'Job Card',
        expected_income: 'Expected Income',
        title: 'Title',
        old_invoice: 'Old Invoice',
        unitId: 'Unit Id',
        main_unit_ar: 'Arabic Main Unit name',
        main_unit_en: 'English Main Unit name',
        add_unit: 'Add Unit',
        update_units: 'Update Unit',
        unit_type: 'Unit Type',
        main_unit: 'Main Unit',
        vendor_code: 'Vendor Code',
        is_finalProduct: 'Item Type',
        saleable: 'Saleable',
        update_procedures: 'Update Procedures',
        procedure: 'Procedure',
        erocedure_date: 'Procedure Date',
        client_priority: 'Priority',
        web_url: 'Web URL',
        purchase_inv: 'Purchase Inv.',
        api_token: 'WhatApp API Token To get This code Please Call (0595907777)',
        send_attachment: 'Send Attachment',
        PublishMe: 'Generate License',
        client_source: 'Client Source',
        add_offer: 'Add Offer',
        offer_name: '',
        offer_start: '',
        offer_end: '',
        add_new: 'Add New',
        campaign_start: 'Campaign Start',
        campaign_start_time: 'Campaign Start Time',
        campaign_title: 'Campaign Title',
        what_to_send: 'What To Send',
        send: 'Send',
        working_time_star: 'Working Start',
        working_time_end: 'Working End',
        product: 'Product',
        last_10_messages: 'Last 10 Messages',
        executed_date: 'Executed Date',
        support_case: 'Support Case',
        support_procedure: 'Support Procedure',
        source: 'Source',
        service_name: 'Service Name',
        available_times: 'Available Times',
        support_type: 'Support Type',
        department: 'Department',
        customer_require: 'customer required',
        subject_require: 'subject required',
        start_date_require: 'start date required',
        executed_date_require: 'executed date required',
        deadline_require: 'deadline required',
        times_require: 'Please select 1 available time at least',
        notes_require: 'notes required',
        alert: "alert",
        error_no_qty: "This item not exist or there is no qtty, please add it throw purcahse invoice",
        mobile_alert_10: 'Mobile number must start with 05 and 10 digit',
        all_calr_plate_field_required: 'All Fields required',
        car_digit_4: 'Car plate digits 4 numbers',
        pay_more_than_price: 'Can not pay more than total of invoice',
        all_field_require: 'All fields required',
        date_out_of_day: 'You can not chose date more than today',
        date_required: 'Date required',
        empty_invoice_error: 'You can not add empty invoice',
        payment_method_error: 'Chose payment method',
        custoday_not_equal: 'Employee custoday not complete payment',
        call: 'Call',
        branches: 'Branches',
        taxes: 'taxes',
        stores: 'stores',
        successfuly_job: 'Job done',
        renewal_amount: 'Renewal Amount',
        renew: 'Renew',
        years: 'Years',
        temporary_expenses: 'Temp. Expenses',
        select_store_before_continue: 'Store Required',
        please_add_one_item_before_continue: 'Please add atleast 1 item to continue',
        company_name_and_company_address_required: 'Company name and address required',
        carstotal: 'Cars Total',
        item_totals: 'Items Total',
        created_by: 'Created By',
        card_supervisor: 'Card Tech',
        technical: 'Technical',
        brand: 'Brnad',
        color: 'Color',
        vin: 'VIN',
        visit_oursite: 'Visit our Site',
        from_store: 'From Store',
        to_store: 'To Store',
        available_qtty: 'Avaiable QTY',
        transfer_qtty: 'Transfer QTY',
        costs: 'Costs',
        wins: 'Total Profit',
        profit: 'Profit',
        unit_namear: 'Unint Arabic name',
        unit_nameen: 'Unint English name',
        no_account_en: 'No account',
        product_namear: "Product Arabic name",
        product_nameen: "Product English name",
        is_saleable: 'Is this product saleable',
        card_paid: "Paid",
        card_not_paid: "Not Paid",
        card_paid_status: 'Payment Status',
        note_text_required: 'Note text required',
        sub_accounts: 'Sub Accounts',
        add_sub_account: 'Add Sub Accounts',
        balance_type: 'Balanced',
        bnl_type: 'Profet & Lost',
        income_type: 'Income',
        other_type: 'Other',
        analyses_account:'Analyses',
        main_account: 'Main',
        debit: 'Debit',
        gov_expenses: 'Government Expenses',
        update_journal:'Update Journal',
        add_new_journal: 'Add new Journal',
        error_date: 'Please chose date inside your Finance year',
        add_fixed_assets: 'Add Fixed Assets',
        udpate_fixed_assets: 'Update Fixed Assets',
        purchase_date: 'Purcahse Date',
        purchase_price: 'Purcahse Price',
        depreciation_rate: 'Depreciation Rate',
        depreciation_period: 'Depreciation Period',
        depreciation_amount: 'Depreciation Amount',
        current_price: 'Current Price',
        fixed_assets_namear: 'Fixes assets Arabic Name',
        fixed_assets_nameen: 'Fixed assets English Name',
        plase_add_assets_ar_name: 'Please add assets arabic name',
        plase_add_assets_en_name: 'Please add assets english name',
        please_add_purchase_price: 'Plase add Purchase Price',
        please_add_purchase_date: 'Plase add Purchase Date',
        please_add_qtty: 'Please add qtty',
        add_out_voucher: 'Add Voucher',
        edit_voucher: 'Update Voucher',
        delete_invoice: 'Delete Invoice',
        return_selected: 'Return Selected',
        delete_selected: 'Delete Selected',
        final_product: 'Final Product',
        raw_material: 'Raw Material',
        factory_item: 'Factory Item',
        salesable: 'Salesable',
        none_salesable: 'None Salesable',
        unit_name: 'Unit Name',
        sale_price: 'Sale Price',
        minimum_salse_price: 'Min Sale Price',
        maximum_salse_price: 'Max Sale Price',
        finger_print_id: 'Finger Print ID',
        employee_name_required: 'Employee name required',
        mobile_number_required: 'Mobile number require',
        base_salary_required: 'Base salary require',
        update_add_employee_success: "Update/Add employee successfuly",
        select_month: 'Select Month',
        select_year: 'Select year',
        custody_amount: 'Custoday Amount',
        attend_late: 'Attend Late',
        left_early: 'Left Early',
        not_attend: 'Not Attend',
        trial_in_balances: 'Trail in balances',
        trial_in_totals: 'Trial in totals',
        trial_balance : 'Trial Balance',
        hide_zero_accounts: 'Hide zero accounts',
        pnl_report: 'Profit and Lost Report',
        this_item_used_you_can_not_delete_it: 'This items used, you can not delte it',
        deleted_successfuly: 'Delteted Successfuly',
        parent_classid: 'Parent Class',
        parent_class_name: 'Parent Name',
        coa_report_with_totals: 'COA Report with Totals',
        coa_report: 'COA Report',
        inventory_report: 'Inventory Report',
        select_store: 'Select Store',
        add_periodic_maint: 'Add Periodc Maint',
        last_visit: 'Last Visit',
        visit_type: 'Visit Type',
        add_vacation: 'Add Vacation',
        number_of_days: 'Number of days',
        period_maint: 'Period Maint.',
        maint_prog: 'Maint. Programs',
        maint_contract: 'Maint. Contracts',
        updatePeriodMaint: 'Update Period',
        add_title: 'Ad Title',
        title_cond: 'Title',
        campaign_price: 'Campaign Price',
        return_quotation: 'Reactive',
        FixedAssets: 'Fixed Assets',
        all_regions: 'All Cities',
        exact_city: 'Exact City',
        campaign_area: 'Campaign Area',
        vehicle_type: 'Vehicle Type',
        campaign_end: 'campaign end',
        campaign_branches: 'Campaign Branches',
        campaign_target: 'Campaign Target',
        campaign_cost: 'Campaign Cost',
        campaign_clients: 'Campaign Clients',
        campaign_profit: 'Campaign Profit',
        created_date: 'Created Date',
        all_branches: 'All Branches',
        view_offer: 'View Offer',
        view_subscriber: 'View Subscriber',
        customer_afforded: 'Customer Afforded',
        car_digi_4: 'Car Plate Number must be 4 digits',
        afforded_customer_name: 'Afforded Client',
        sales_total: 'Sales Total',
        sales_ftotal: 'Sales Total With VAT',
        all_total: 'Requested Total',
      },
      ar:{
        all_total: "اجمالي المطلوب",
        sales_total: 'اجمالي المبيعات',
        sales_ftotal: 'اجمالي المبيعات مع الضريبة',
        afforded_customer_name: 'عميل التأمين',
        customer_afforded: 'تحمل العميل',
        view_subscriber: 'عرض الاشتراك',
        view_offer: 'كرت العرض',
        all_branches: 'كل الفروع',
        created_date: 'تاريخ الاضافة',
        campaign_profit: 'الدخل',
        campaign_clients: 'عدد العملاء',
        campaign_cost: 'تكلفة البرنامج',
        campaign_target: 'العدد المستهدف',
        campaign_branches: 'الفروع المستهدفة',
        campaign_end: 'تاريخ نهاية العرض',
        vehicle_type: 'نوع المركبة',
        campaign_area: 'المنطقة المستهدفة',
        all_regions: 'كل المدن',
        exact_city: 'مدينة محددة',
        FixedAssets: 'الاصول الثابتة',
        return_quotation: 'تفعيل العرض',
        campaign_price: 'سعر العرض',
        add_title: 'اضافة بنود العقد',
        title_cond: 'البند',
        updatePeriodMaint: 'تعدل الصيانة الدورية',
        period_maint: 'صيانة دورية',
        maint_prog: 'برامج الصيانة',
        maint_contract: 'عقود صيانة',
        number_of_days: 'عدد الايام',
        add_vacation: 'اضافة اجازة',
        po_number: 'رقم المطالبة',
        visit_type: 'نوع الزيارة',
        last_visit: 'آخر زيارة',
        add_periodic_maint: "أضافة صيانة",
        select_store: 'اختر المستودع',
        inventory_report: 'تقرير الجرد',
        coa_report: 'تقرير الدليل',
        coa_report_with_totals: 'تقرير الدليل بالمجاميع',
        parent_class_name: 'اسم الحساب الرئيسي',
        parent_classid: 'الحساب الرئيسي',
        deleted_successfuly: 'تم الحذف بنجاح',
        this_item_used_you_can_not_delete_it: 'العنصر المراد حذفه مستخدم، ولا يمكن حذفه',
        pnl_report: 'تقرير الارباح والخسائر',
        hide_zero_accounts: 'اخفاء المجاميع الصفرية',
        trial_balance : 'ميزان المراجعة',
        trial_in_balances: 'الميزان بالارصدة',
        trial_in_totals: 'الميزان بالمجاميع',
        attend_late: 'تاخير حضور',
        left_early: 'انصراف مبكر',
        not_attend: 'غياب',
        select_year: 'اختر السنة',
        select_month: 'اختر الشهر',
        update_add_employee_success: "تمت اضافة / تعديل الموظف بنجاح",
        employee_name_required: 'اسم الموظف مطلوب',
        mobile_number_required: 'رقم الجوال مطلوب',
        base_salary_required: 'الراتب الاساسي مطلوب',
        finger_print_id: 'رقم تعريف البصمة',
        unit_name: 'اسم الوحدة',
        sale_price: 'سعر البيع',
        minimum_salse_price: 'الحد الادنى للبيع',
        maximum_salse_price: 'الحد الاعلى للبيع',
        salesable: 'قابل للبيع',
        none_salesable: 'غير قابل للبيع',
        final_product: 'منتج نهائي',
        raw_material: 'مواد خام',
        factory_item: 'منتج مصنع',
        return_selected: 'ارجاع المحدد',
        delete_selected: 'حذف المحدد',
        delete_invoice: 'حذف الفاتورة',
        edit_voucher: 'تعديل السند',
        add_out_voucher: 'اضافة سند صرف',
        please_add_qtty: 'يرجى اضافة الكمية',
        please_add_purchase_price: 'يرجى اضافة سعر الشراء',
        please_add_purchase_date: 'يرجى اضافة تاريخ الشراء',
        plase_add_assets_ar_name: 'يرجى ادخال اسم الاصل بالعربي',
        plase_add_assets_en_name: 'يرجى ادخال اسم الاصل بالانجليزي',
        fixed_assets_namear: 'اسم الاصل بالعربي',
        fixed_assets_nameen: 'اسم الاصل بالانجليزي',
        add_fixed_assets: 'إضافة اصل ثابت',
        udpate_fixed_assets: 'تعديل اصل ثابت',
        purchase_date: 'تاريخ الشراء',
        purchase_price: 'سعر الشراء',
        depreciation_rate: 'نسبة الاهلاك',
        depreciation_period: 'مدة الاهلاك',
        depreciation_amount: 'قيمة الاهلاك',
        current_price: 'السعر الحالي',
        error_date: 'تاريخ القيد خارج نطاق السنة المالية الحالية يرجى اختيار التاريخ المناسب',
        update_journal:'تعديل القيد',
        add_new_journal: 'اضافة قيد',
        gov_expenses: 'مصروفات حكومية',
        debit: 'مدين',
        analyses_account:'حساب تحليلي',
        main_account: 'حساب فرعي',
        balance_type: 'ميزانية',
        bnl_type: 'ارباح وخسائر',
        income_type: 'إيرادات',
        other_type: 'أخرى',
        add_sub_account: 'اضافة حساب فرعي',
        sub_accounts: 'الحسابات الفرعية',
        note_text_required: 'يرجى اضافة نص الملاحظة',
        card_paid_status: 'حالة السداد',
        card_paid: "مسدد",
        card_not_paid: "غير مسدد",
        is_saleable: 'هل المنتج قابل للبيع',
        product_namear: "اسم المنتج بالعربي",
        product_nameen: "اسم المنتج بالانجليزي",
        no_account_ar: 'بدون حساب',
        unit_namear: 'اسم الوحده بالعربي',
        unit_nameen: 'اسم الوحده بالانجليزي',
        profit: 'الارباح',
        wins: 'صافي الربح',
        costs: 'التكاليف',
        available_qtty: 'الكمية المتاحة',
        transfer_qtty: 'الكمية المحولة',
        from_store: 'التحويل من',
        to_store: 'التحويل إلى',
        visit_oursite: 'زيارة مقرنا',
        vin: 'رقم الشاص',
        color: 'اللون',
        brand: 'الماركة',
        technical:'الفني',
        carstotal: 'عدد السيارات',
        item_totals: 'عدد الاصناف',
        created_by: 'انشأت بواسطة',
        card_supervisor: 'الفني',
        company_name_and_company_address_required: 'اسم الشركة والعنوان دائما مطلوبين',
        please_add_one_item_before_continue: 'يرجى اضافة صنف واحد على الاقل',
        select_store_before_continue: 'يرجى اختيار المستودع',
        temporary_expenses: 'مصروفات مستردة',
        years: 'السنوات',
        renew: 'تجديد',
        renewal_amount: 'التجديد السنوي',
        successfuly_job: 'تمت العملية بنجاح',
        branches: 'الفروع',
        stores: 'المستودعات',
        taxes: 'الضرائب',
        call: 'اتصال',
        custoday_not_equal: "عهدة الموظف لا تغطي تكاليف الفاتورة",
        payment_method_error: "يجب اختيار طريقة دفع قبل المتابعة أو عهدة من موظف",
        empty_invoice_error: 'لا يمكن اضافة فاتورة فارغة',
        date_required: 'يرجى اختيار التاريخ',
        date_out_of_day: "تاريخ الفاتورة يجب ان لا يتجاوز التاريخ الحالي تم اعادة التاريخ إلى تاريخ اليوم",
        all_field_require: 'يرجى اكمال كافة الحقول المطلوبة',
        pay_more_than_price: "لا يمكن سداد مبلغ يتجاوز قيمة اجمالي الفاتورة",
        car_digit_4: "يجب أن تكون ارقام اللوحة مؤلفة من اربع ارقام",
        all_calr_plate_field_required: "يجب تعبئة حقول حروف اللوحة بالكامل",
        mobile_alert_10: "رقم الجوال يجب ان يكون من عشرة ارقام يبدأ برقم 05",
        error_no_qty: "الصنف المختار غير متوفر حاليا... يرجى اضافة فاتورة مشتريات قبل اضافة المخزون ليتم احتساب ضريبة القيمة المضافة بشكل صحيح",
        alert: 'تنبيه',
        notes_require: 'وصف التذكرة مطلوب دائما',
        times_require: 'يرجى اختيار وقت لتنفيذ التذكرة',
        deadline_require: 'تاريخ الانتهاء مطلوب دائما',
        executed_date_require: 'تاريخ التنفيذ مطلوب دائما',
        start_date_require: 'تاريخ البدء مطلوب دائما',
        subject_require: 'عنوات التذكرة مطلوب دائما',
        customer_require: 'يرجى اختيار العميل',
        department:'القسم',
        support_type: 'نوع الدعم',
        available_times: 'الاوقات المتاحة',
        service_name: 'نوع الدعم',
        source: 'المصدر',
        support_case: 'نوع الدعم',
        support_procedure: 'الاجراء المطلوب',
        executed_date: 'تاريخ التنفيذ',
        last_10_messages: 'آخر عشر رسائل',
        product: 'المنتج',
        working_time_star: 'بدء العمل',
        working_time_end: 'انتهاء العمل',
        send: 'إرسال',
        what_to_send: 'نوع الارسال',
        campaign_start: 'تاريخ بداية العرض',
        campaign_start_time: 'وقت البدء',
        campaign_title: 'اسم البرنامج',
        add_new: 'اضافة جديد',
        offer_name: '',
        offer_start: '',
        offer_end: '',
        add_offer: 'اضافة عرض',
        client_source: 'مصدر العميل',
        PublishMe: 'اصدار ترخيص',
        send_attachment: 'السماح بارسال ملفات',
        expire_date: 'تاريخ الانتهاء',
        api_token: 'كود تفعيل الواتس اب (للحصول على الكود الاتصال بـ 0595907777)',
        purchase_inv: 'فاتورة المشتريات',
        web_url: 'رابط النسخة',
        client_priority: 'الأهمية',
        erocedure_date: 'تاريخ الاجراء',
        procedure: 'الاجراء',
        update_procedures: 'تعديل الاجراء',
        saleable: 'قابل للبيع',
        is_finalProduct: 'نوع الصنف',
        vendor_code: 'رقم تعريف المورد',
        unit_type: 'نوع الوحدة',
        main_unit: 'الوحدة الاساسية',
        update_units: 'تعديل وحدة',
        add_unit: 'إضافة وحدة',
        main_unit_ar: 'الوحدة الرئيسية عربي',
        main_unit_en: 'الوحدة الرئيسية انجليزي',
        unitId: 'المعرف',
        old_invoice: 'الفاتورة السابقة',
        title: 'العنوان',
        expected_income: 'الدخل المتوقع',
        job_cards: 'اوامر التشغيل',
        contracts: 'العقود',
        supports: 'الدعم',
        meter_car: 'قراءة العداد',
        contract_date: 'تاريخ العقد',
        contract_expire_date: 'تاريخ الانتهاء',
        contract_start_date: 'تاريخ البدء',
        cars_number: 'عدد السيارات',
        last_maint_by: 'اخر صيانة بواسطة',
        last_maint_date: 'تاريخ اخر صيانة',
        contract_id: 'رقم العقد',
        add_contract: 'إضافة عقد',
        start_qtty: 'الرصيد الافتتاحي',
        cost_price: 'سعر التكلفة',
        add_sinventory: 'إضافة مخزون',
        add_transfer: 'إضافة تحويل',
        trans_from: 'تحويل من',
        trans_to: 'تحويل إلى',
        trans_type: 'نوع التحويل',
        received_by: 'استلام بواسطة',
        account_number: 'رقم الحساب',
        store_name_ar: 'اسم المستودع عربي',
        store_name_en: 'اسم المستودع انجليزي',
        add_store: 'اضافة مستودع',
        card_type: 'نوع الصيانة',
        new_sales: 'كرت جديد',
        return_sales: 'كرت رجيع',
        mobile_required: 'رقم الاتصال مطلوب دائما',
        super_visor_required: 'يجب اختيار مشرف',
        superVisor: 'مشرف مبيعات',
        checklist: 'قائمة التحقق',
        task_title: 'عنوان المهمة',
        task: 'المهمة',
        employee: 'الموظف',
        project_employees: 'موظفي المشروع',
        project_close_date: 'اغلاق المشروع',
        project_start: 'بداية المشروع',
        project_deadline: 'نهاية المشروع',
        project_owner: 'مسؤول المشروع',
        project_type: 'نوع المشروع',
        project_title: 'عنوان المشروع',
        support: 'الدعم الفني',
        industry: 'نوع العمل',
        day_name: 'اليوم',
        exeute_date: 'تاريخ التنفيذ',
        task_priority: 'الأهمية',
        companies: 'فواتير شركات',
        owner_name: 'مالك السيارة',
        add_work_order: 'اضافة امر عمل',
        employee_name: 'اسم الموظف',
        number_of_tasks: 'عدد المهام',
        closed_tasks: 'مغلق',
        canceled_tasks: 'ملغي',
        opened_tasks: 'تحت الاجراء',
        advanced_payment_deleted: 'تم حذف السلفة بنجاح',
        employee_deleted: 'تم حذف الموظف بنجاح',
        cannot_delete_this_vendor_invoices_found: 'المورد لديه فواتير مشتريات - مصروفات لا يمكن حذفه',
        cannot_delete_this_vendor_payment_found: 'هناك دفعات مسددة للمورد لا يمكن حذف المورد',
        vendor_delete_done: 'تم حذف المورد بنجاح',
        cannot_delete_this_customer_invoices_found: 'لا يمكن حذف عميل لديه فواتير',
        cannot_delete_this_customer_cards_found: 'لا يمكن حذف عميل لديه أوامر تشغيل',
        custoemr_delete_done: 'تم حذف العميل بنجاح',
        client_type: 'نوع العميل',
        message_title: 'عنوان الرسالة',
        image_url: 'رابط الصورة',
        document_url: 'رابط المستند',
        vedio_url: 'رابط الفيديو',
        number_list: 'قائمة الارقام',
        number_list_notes: 'ضع فاصلة بين الارقام',
        sent_type: 'نوع الارسال',
        send_to: 'ارسال إلى',
        send_whatsapp: 'ارسال واتس',
        send_date: 'تاريخ الارسال',
        message_type: 'نوع الرسالة',
        message_text: 'نص الرسالة',
        customer_number: 'عدد العملاء',
        sending_number: 'عدد المرسل لهم',
        media_link: 'عرض الملف',
        add_support: 'اضافة تذكرة',
        support_ticket: 'الدعم الفني',
        industrial: 'الصناعية',
        update_task: 'تحديث مهمة',
        whats_type: 'نوع الرسالة',
        whats_url_multi: 'رابط - الصورة او المستند او الفديو',
        last_action_date: 'تاريخ اخر اجراء',
        yes_19: 'اضافة جهاز شاشة 19 انش',
        yes_24: 'اضافة جهاز شاشة 24 انش',
        no_devices: 'لا يوجد اجهزة',
        include_computers: 'اضافة اجهزة',
        copies: 'عدد التراخيص',
        license_url: 'رابط الترخيص',
        task_response: 'المسؤول',
        note_text: 'نص الملاحظة',
        note_type: 'نوع المالحظة',
        generate_license: 'اصدار ترخيص',
        view_crm: 'عميل محتمل',
        agent: 'المندوب',
        next_action_date: 'تاريخ الاجراء',
        next_action: 'الاجراء القادم',
        last_action: 'الاجراء السابق',
        last_update: 'اخر تحديث',
        job_order: 'أمر العمل',
        training: 'التدريب',
        task_type: 'نوع المهمة',
        // added_by: 'اضافة بواسطة',
        note_date: 'تاريخ الملاحظة',
        edit_calendar: 'تعديل الحجز',
        change_booking_date: 'تغيير الموعد',
        booking_procedure:'اجراءات الحجز',
        work_name: 'وصف الموعد',
        estimated_price: 'السعر التقريبي',
        choose_custoemr: "اختر العميل",
        calendar_table: 'جدول الحجوزات',
        cancel_card: 'الغاء البطاقة',
        ready_to_deliver: 'جاهز للتسليم',
        brand_name: 'الشركة المصنعة',
        add_new_status: 'اضافة حالة جديدة',
        carsnumber: 'عدد السيارات',
        work_type: 'نوع العمل',
        // item_name: 'اسم قطع الغيار',
        add_spare_part: 'اضافة قطع غيار',
        add_new_spare_part: 'اضافة قطع غيار',
        spare_part_item_code: 'رقم القطعة',
        ment_description: 'أعمال الصيانة',
        customer_status: 'حالة العميل',
        tasks_status: 'المهام',
        warantly_status: 'الضمان',
        calendar_status: 'الحجوزات',
        book_calendar: 'الحجوزات',
        start_time: 'توقيت البدء',
        deadline_time: 'توقيت الانتهاء',
        add_booking: 'اضافة حجز',
        start_time_required: 'توقيت البدء والانتهاء مطلوبين دائما',
        plate_number_required: 'يجب اختيار رقم اللوحة',
        add_warantly: 'اصلاح على الضمان',
        last_update_by: 'تحديث بواسطة',
        last_update_date: 'تاريخ اخر تحديث',
        closed_date: 'تاريخ الاغلاق',
        task_start_end_date_required: 'تاريخ البدء والانتهاء مطلوبين دائما',
        task_description_required: 'يجب اضافة وصف للمهمة',
        task_to: 'تحويل الى',
        dead_line: 'تاريخ الانتهاء',
        task_description: 'وصف المهمة',
        add_task: 'اضافة مهمة',
        tasks: 'المهام',
        financial_request: 'المطالبات المالية',
        report: 'تقرير',
        unpaid_job_orders: 'غير مفوتر',
        finance_report: 'الحركة المالية',
        add_notes: 'اضافة ملاحظة',
        cars: 'السيارات',
        add_fincance_request: 'مطالبة مالية',
        view_customer: 'استعراض عميل',
        name_info_required: "اسم العميل مطلوب ويجب ان يكون اكثر من خمس احرف",
        mobile_info_required: 'رقم الاتصال مطلوب ويجب ان يبدأ بـ 05',
        add_your_cash: 'ادخل المبلغ النقدي هنا بحسب الفئات',
        book_tables: 'حجز طاولة',
        menu: 'القائمة',
        smiller_products: 'الاصناف المتشابهة',
        cashair: 'الكاشير',
        managment: 'الادارة',
        invoice_summary: 'ملخص الفاتورة',
        add_client_voucher: 'سداد عميل',
        total_payment_to_Client: 'المبلغ المستحق الدفع من العميل: ',
        client_appointment: 'حجز موعد',
        client_statement_pos: 'حساب عميل',
        total_payment_to_vendor: 'اجمالي الدفعات المتسحقة للمورد: ',
        clode_shift: 'اغلاق يومية',
        add_purchase: 'اضافة مشتريات',
        calendar: 'المواعيد',
        open_new_shift: 'فتح الوردية',
        opening_balance: 'الرصيد الافتتاحي',
        _total_1_ryals: '1 SAR',
        _total_5_ryals: '5 SAR',
        _total_10_ryals: '10 SAR',
        _total_20_ryals: '20 SAR',
        _total_50_ryals: '50 SAR',
        _total_100_ryals: '100 SAR',
        _total_500_ryals: '500 SAR',
        open_shift: 'فتح الوردية',
        remainpso: 'المتوفر',
        current_qty: 'الكمية المتوفرة',
        total_income: 'اجمالي المبيعات',
        sales_qtty: 'الكمية المباعة',
        max_items_sales: 'الأكثر مبيعا',
        less_items_sates: 'الاصناف الراكدة',
        zero_items: 'الاصناف المنتهية',
        daily_report: 'تقرير اليومية',
        search_by_name: 'البحث بالاسم',
        today_date: 'تاريخ اليوم',
        fix_it: 'تثبيت',
        monthly_deduct: 'الخصم الشهري',
        deduct_amount: 'مبلغ الخصم',
        monthly_late: 'التأخير الشهري',
        deduct_type: 'نوع الخصم',
        minute_late: 'التاخير بالدقائق',
        deduct_resone: 'سبب الخصم',
        returned: 'المرتجع',
        part_amount: 'قيمة القسط',
        number_of_parts: 'عدد الاقساط',
        paid_in_advanced_total: 'اجمالي السلف',
        deduct: 'الخصومات',
        service: 'خدمات',
        mentanace_card: 'أوامر التشغيل',
        custdy: 'العهد',
        salary_total: 'اجمالي الراتب',
        lated_salaries: 'رواتب متأخرة',
        remain_salary: 'صافي الراتب',
        invoice_percentage: 'نسبة المبيعات',
        card_percentage: 'نسبة كروت الصيانة',
        posted_salary: 'الراتب المصدر',
        indemnity: 'مكافأة نهاية الخدمة',
        post_salary: 'اصدار راتب',
        add_reward: 'اضافة مكافأة',
        pay_advanced: 'اضافة سلفة',
        deduct_from_salary: 'خصم من الراتب',
        emp_statment: 'كشف حساب ',
        pay_from_cash: 'الدفع من الصندوق',
        pay_from_custdy: 'الدفع من العهدة',
        terms: 'الشروط والأحكام',
        vendor_payments: 'دفعات موردين',
        work_hours: 'ساعات العمل',
        terms_and_conditions: 'الشروط والاحكام',
        back: 'رجوع',
        customer_sign: 'توقيع العميل',
        width: 'عرض',
        thickness: 'سماكة',
        limited: 'محدود',
        custody_statement: 'كشف عهد موظف',
        add_custody: 'اضافة عهدة',
        remain_custody: 'المتبقي من العهدة',
        return_custody: 'المدفوع من العهدة',
        custody: 'عهدة الموظف',
        full_cancel: 'الغاء كامل',
        partial_cancel: 'الغاء جزئي',
        full_cancel_reson: 'سبب الغاء كامل الطلب',
        cancel_reson: 'سبب الالغاء',
        cancel_qtty: 'الكمية الملغاه',
        order_items: 'اصناف الطلب',
        order_details: 'تفاصيل الطلب',
        order_time: 'وقت الطلب',
        order_date: 'تاريخ الطلب',
        orderid: 'رقم الطلب',
        view_order: 'عرض الطلب',
        add_order: 'اضافة طلب',
        cancel_order: 'الغاء الطلب',
        download: 'تحميل',
        file_name: 'اسم الملف',
        file_size: 'حجم الملف',
        client_statment: 'كشف حساب',
        salary_paid: 'سند صرف راتب',
        add_vendor_voucher: 'تسديد مورد',
        period_balance: 'رصيد المدة',
        credit_note: 'اشعار دائن',
        purchase_and_expenses: 'مشتريات ومصروفات',
        total_balance: "اجمالي الرصيد",
        previews_balance: "الرصيد السابق",
        today_balance: 'رصيد اليوم',
        daily_summary_for_date:'الملخص اليومي لتاريخ ',
        time: 'الوقت',
        payment_type: 'طريقة الدفع',
        amount: 'المبلغ',
        debet: 'مدين',
        details: 'التفاصيل',
        agree: 'موافق',
        row_type: 'نوع الاضافة',
        add_new_work_expenses: 'اضافة اصالاحات اخرى او مصروفات',
        complete: 'انهاء',
        vin_numebr: 'رقم الهيكل',
        isbilled: 'الفوترة',
        approve_card: 'أمر التشغيل',
        chose_car_before_continue: 'يجب اختيار سيارة حتى تتم الاضافة',
        plase_select_car_scratch: 'يجب عليك ان ترسم على صورة السيارة اماكن الصدمات او الخدوش',
        you_must_add_atleast_one_work_item: 'يجب عليك اضافة وصف واحد على الاقل - او تأكد من انك ضغطت على زر اضافة بجانب الوصف بعد تعبئة البيانات والاسعار',
        you_must_add_one_car_atleast: 'يجب عليك اضافة سيارة على الاقل للمتابعة',
        car_information_must_be_complated: 'معلومات السيارة الموديل ونوع السيارة للوحة رقم  {{plate_number}} مطلوب للمتابعة',
        company_name_required: 'اسم الشركة مطلوب للمتابعة',
        customer_name_required: 'اسم العميل مطلوب للمتابعة',
        company_address_required: 'عنوان الشركة مطلوب للمتابعة',
        entity_name_required: 'اسم الجهة الحكومية مطلوب للمتابعة',
        chose_customer: 'اختار العميل',
        chose_company: 'اختر الشركة',
        chose_entity: 'اختر الجهة',
        reset: 'مسح',
        undo: 'تراجع',
        cars_status: 'حالة السيارات عند الاستلام',
        add_new_card: 'اضافة امر تشغيل',
        add_invocie: 'اضافة فاتورة',
        are_you_sure: 'هل أنت متاكد بانك تريد المتابعة',
        changeNote: 'اضافة الملاحظات',
        out_payments: 'سندات الصرف',
        in_payments: 'سندات القبض',
        cars_list: 'قائمة السيارات',
        total_discount: 'مبلغ الخصم',
        total_b_d: 'الاجمالي قبل الخصم',
        chose_car: 'اختار السيارة',
        expenses_invoices: 'فواتير المصروفات',
        bid_number: 'رقم المنافسة',
        view_quot_pdf: 'تحميل العرض',
        footer_extra: 'اضافات للفوتر',
        add_car: 'اضافة سيارة',
        entity_name: 'اسم الجهة',
        branch_name: 'اسم الفرع',
        request_number: 'رقم الطلب',
        order_number: 'رقم الأمر',
        po_date: 'تاريخ المطالبة',
        new_customer: 'عميل جديد',
        add_invoice_notes: 'اضافة ملاحظات على الفاتورة (ضمان - اشتراطات - غيرها)',
        whatsapp: 'واتس اب',
        for_more_information_or_support_firstline: 'للدعم الفني الاتصال',
        for_more_information_or_support_secondline: '0566566160 - 0595907777 - 0565100268',
        customer_company_info_required: 'عنوان واسم الشركة مطلوب',
        view_expenses: 'عرض فاتورة المصروفات',
        view_quotation: 'عرض السعر',
        expenses_type_reoprt: 'تقرير أنواع المصروفات',
        print_out_voucher: 'طباعة سند صرف',
        day: 'اليوم',
        this_item_service_item: 'لا يمكن اضافة الصنف الخدمي إلى فاتورة المشتريات',
        inventory_item: 'صنف مخزون',
        service_item: 'صنف خدمي',
        product_type: 'نوع الصنف',
        product_category: 'القسم',
        price_without_vat: 'السعر بدون ضريبة',
        price_inc_vat: 'السعر شامل الضريبة',
        default_cost: 'التكلفة الافتراضية',
        added_value_tax: 'تفعيل ضريبة القيمة المضافة',
        current_qtty: 'الكمية المتوفرة',
        add_edit_product: 'اضافة / تعديل منتج',
        // fftotal: 'الاجملاي',
        vat_summary: 'ملخص التقرير الضريبي',
        return_invoices: 'مرتجع الفواتير',
        view_page: 'معاينة الصفحة',
        government_clinet: ' حكومي',
        add_attachement: 'اضافة مرفقات',
        car_status_receive: 'حالة استلام السيارة',
        printing_header: 'هيدر الطباعة',
        invoice_body: 'صفحة الفاتورة',
        voucher_body: 'صفحة السند',
        card_body: 'صفحة كرت الصيانة',
        printing_footer: 'فوتر الطباعة',
        newpassword: 'كلمة المرور الجديدة',
        oldpassword: 'كلمة المرور القديمة',
        changePassword: 'تغيير كلمة المرور',
        fftotal: 'الاجمالي',
        total_without_vat: 'المجموع بدون ضريبة',
        chose_saler_name: 'اختر اسم البائع',
        chose_payment_method: 'اختر طريقة الدفع',
        chose_vat_type: 'اختر نوع الضريبة',
        chose_invoice_type: 'اختر نوع الفاتورة',
        chose_customer_type: 'اختر نوع العميل',
        print_in_voucher: 'طباعة سند قبض',
        save_n_print: 'حفظ وطباعة',
        car_ifnormation_required: 'بيانات السيارة مطلوبة رقم اللوحة، نوع السيارة و سنة الصنع',
        add_direct_invoice: 'اضافة فاتورة مباشرة',
        name_ar_en_required: 'نوع المصروف عربي انجليزي مطلوب دائما',
        add_expense_type: 'اضافة نوع مصروفات',
        invoice_export: 'اصدار فاتورة',
        sales_vat: 'ضريبة المبيعات',
        bill_vat: 'ضريبة المشتريات',
        print_voucher: 'طباعة ',
        purchase_summary: 'ملخص المشتريات',
        purchase_bill: 'فواتير المشتريات ',
        old_balance: 'رصيد سابق',
        expnese: 'المصروفات',
        other: 'اخرى',
        income_expense_report: 'تقرير الدخل اليومي',
        expenses_type_added: 'تم اضافة نوع المصروف بنجاح',
        add_new_expense_type: 'اضافة نوع مصروفات جديد',
        expenses_type_updated: 'تم تعديل نوع المصروف بنجاح',
        expense_namear: 'نوع المصروف عربي',
        expense_nameen: 'نوع المصروف انجليزي', 
        tafweed: 'التفويض',
        terms_of_use: 'شروط الاستخدام',
        logo_img: 'صورة الشعار',
        year: 'السنة',
        chose_month: ' الشهر',
        convert_to_invoice: 'تحويل لفاتورة',
        cards_expsenses: 'مصاريف الكروت',
        cards_number: 'عدد الكروت',
        catd_totals: 'اجمالي الكروت',
        totalPercent: 'النسبة المستحقة',
        percent_report: 'تقرير النسب',
        salary_report: 'تقرير الرواتب',
        sendWhats: '<i class="fa fa-whatsapp"></i>',
        total_invoices: 'اجمالي الفواتير',
        total_remain: 'اجمالي المتبقي',
        vin_number: 'رقم الهيكل',
        start_date: 'تاريخ البدء',
        end_date: 'تاريخ الانتهاء',
        worker_name: 'اسم الفني',
        work_price: 'وصف العمل',
        you_cannot_delete_this_item_paid_more_than_total: 'لا يمكنك حذف هذا العنصر وذلك لأن قيمة الدفعات اكبر من قيمة الكرت',
        company_name_en: 'Company English Name',
        contact_number: 'رقم الاتصال',
        booktime: 'وقت الطلب',
        orders: 'الطلبات',
        add_product: 'اضافة منتج',
        welcome: "مرحبا ",
        username: 'اسم المستخدم',
        item_cost: 'التكلفة',
        sent_requests: 'الارساليات',
        print_page: 'طباعة الصفحة',
        print_pagexport_pdfe: 'تصدير PDF',
        ment_req_cards_new: 'طلبات الصيانة الجديدة',
        ment_req_cards_late: 'طلبات الصيانة المتأخرة',
        ment_req_cards_closed: 'طلبات الصيانة المغلقة',
        payment_requests:'الدفعات',
        requests:'الارساليات',
        home: 'الرئيسية',
        additional_method: 'خصم اضافي غير خاضع للضريبة',
        adding_date: 'تاريخ الاضافة',
        ammount: 'القيمة',
        method: 'العملية',
        add_project: 'اضافة مشروع',
        projects: 'المبيعات',
        expeneses_type: 'نوع المصروفات',
        account_name: 'اسم الحساب',
        integrated_with_expenses: 'ربط المصروفات',
        account_integrated: 'الربط مع',
        default_type: 'قيمة الحساب',
        account_type: 'نوع الحساب',
        acount_name: 'اسم الحساب',
        update_acount: 'تحديث الحساب',
        accoutn_option: 'خيارات الحساب',
        mainaccount: 'الحساب الرئيسي',
        account_name_ar: 'الاسم العربي',
        account_name_en: 'الاسم الانجليزي',
        add_new_account: 'حساب جديد',
        view_employee: 'استعراض موظف',
        customer_addedd: 'تمت اضافة العميل بنجاح',
        please_choose_worker: 'يرجى اختيار منفذ العمل',
        job_price_required: 'يرجى اضافة سعر شغل اليد حتى تستطيع المتابعة',
        car_info_required: 'معلومات السيارة مطلوبة',
        delivery_date_required: 'تاريخ التسليم مطلوب',
        customer_info_required: 'رقم الاتصال يجب ان يبدأ بـ 05 واسم العميل مطلوب دائما',
        maintenance_cards: 'اوامر التشغيل',
        sales_crm: 'المبيعات',
        exp_management: 'مصروفات ادارية',
        bnl_report: 'الارباح والخسائر',
        gozi_report: 'تقرير التأمينات',
        balance_report: 'الميزانية',
        addJV: 'اضافة قيد',
        notes: 'ملاحظات',
        jvnom: 'رقم القيد',
        vendor_name_required: 'اسم المورد مطلوب ويجب ان يكون اكبر من خمس احرف',
        finance_jvs: 'القيود',
        vendor_mobile_required: 'رقم اتصال المورد مطلوب ويجب ان يكون اكثر من خمس ارقام',
        vendor_updated_successufly: 'تمت اضافة / تعديل المورد بنجاح',
        update_vendor: 'تعديل مورد',
        add_vendor: 'اضافة مورد',
        invoice_total: 'اجمالي الفواتير',
        vat_total: 'قيمة الضريبة',
        vendors: 'الموردين',
        price: 'السعر',
        late: 'متأخر',
        fixed_assets: 'الاصول الثابتة',
        class_name: 'اسم الحساب',
        add_pay_type: 'إضافة طريقة دفع',
        statment: 'كشف حساب',
        namear: 'الاسم العربي',
        nameen: 'الاسم الانجليزي',
        classid: 'رقم الحساب',
        amounttotal: 'اجمالي المبلغ',
        car_report: 'تقرير المركبة',
        definition: 'ربط الشجرة ',
        itotals_per_paymethod: 'اجمالي الدخل بحسب طريقة الدفع',
        ototals_per_paymethod: 'اجمالي المصروفات بحسب طريقة الدفع',
        account_statement: ' كشف حساب',
        account_balance_report: 'تقرير الموازنة',
        openingbalance: 'الرصيد الافتتاحي',
        transaction: 'رصيد العمليات',
        chart_of_account: 'شجرة الحسابات',
        paytypes: 'طرق الدفع',
        balance: 'الرصيد',
        accounts: 'الحسابات',
        incomes: 'الدخل',
        amountprice: 'المبلغ',
        account: 'الحساب',
        vlaue: 'القيمة',
        langname: 'ar',
        dir: 'rtl',
        ldir: 'ltr',
        algin: 'right',
        lalgin: 'left',
        invoiceno: 'رقم الفاتورة',
        plate_number: 'رقم اللوحة',
        main_menu: 'القائمة',
        search: 'بحث',
        invoices: 'الفواتير',
        add_invoice: 'اضافة فاتورة',
        invtype: 'نوع الفاتورة',
        customer: 'العميل',
        mobile: 'رقم الاتصال',
        plate: 'اللوحة',
        model: 'الموديل',
        inv_date: 'التاريخ',
        total: 'المجموع',
        vat: 'الضريبة',
        ftotal: 'الاجمالي مع الضريبة',
        paid: 'المدفوع',
        remain: 'المتبقي',
        remaremain_amountin: 'المبلغ المتبقي على العميل',
        action: 'الاجراء',
        status: 'الحالة',
        view: 'عرض',
        totals: 'المجموع',
        close: 'اغلاق',
        customer_name: 'اسم العميل',
        customer_type: 'نوع العميل',
        company_name: 'اسم الشركة / الجهة',
        comapny_address: 'عنوان الشركة',
        company_vatid: 'الرقم الضريبي',
        received_amount: 'المبلغ المدفوع',
        car_model: 'نوع السيارة',
        car_year: 'سنة الصنع',
        car_color: 'اللون',
        invoice_type: 'نوع الفاتورة',
        vat_type: 'نوع الضريبة',
        payment_method: 'طريقة الدفع',
        saler_name: 'البائع',
        invoice_date: 'تاريخ الفاتورة',
        invoice_details: 'تفاصيل الفاتورة',
        item_code: 'رمز الصنف',
        description: 'الوصف',
        qtty: 'العدد',
        item_price: 'السعر',
        discount: 'الخصم',
        create_invoice: 'إنشاء فاتورة',
        cash_payment: 'الدفع نقدا',
        bank_payment: 'تحويل بنكي',
        span_payment: 'الدفعة بواسطة الشبكة',
        add: 'إضافة',
        individual_client: ' فردي',
        company_clinet: ' شركة',
        prices_include_vat: 'السعر شامل الضريبة',
        prices_exclusive_vat: 'السعر غير شامل الضريبة',
        prices_without_vat: 'بدون ضريبة',
        none_paid_invoice: 'فاتورة آجلة',
        paid_invoice: 'فاتورة نقدية', 
        multi_payments: 'الدفع المتعدد',
        required_field: 'الحقل مطلوب',
        view_purchase_bill: 'عرض فاتورة مشتريات',
        you_can_not_add_empty_invoice: 'لا يمكن اضافة فاتورة فارغة',
        company_ifnormation_required: 'يرجى ادخال بيانات الشركة اسم الشركة، رقم السجل، العنوان',
        invoice_added: 'تمت اضافة الفاتورة بنجاح',
        can_not_add_item_withoud_discription: 'لا يمكن اضافة صنف بدون وصف',
        item_qtty_must_be_more_than_zero: 'لا يمكن اضافة صنف بدون كمية',
        item_price_must_be_more_than_zero: 'لا يمكن اضافة صنف بدون سعر',
        print_invoice: 'طباعة الفاتورة',
        view_pdf: 'تحميل الفاتورة',
        invoice_return: 'ارجاع الفاتورة',
        make_payment: 'سداد دفعة',
        invoice_tme: 'وقت الفاتورة',
        return_date: 'تاريخ الارجاع',  
        return_time: 'وقت الارجاع',  
        return_case: 'سبب الارجاع',
        total_exclusive_vat: 'اجمالي بدون ضريبة',
        total_include_vat: 'اجمالي مع ضريبة',
        payments: 'الدفعات',
        payment_date: 'تاريخ الدفعة',
        payment_time: 'توقيت الدفعة',
        payment_amount: 'قيمة الدفعة ',
        payment_cash: 'نقدا',
        payment_span: 'شبكة',
        payment_bank: 'تحويل',
        return_invoice: 'ارجاع فاتورة',
        return_amount: 'المبلغ المرتجع',
        return: 'ارجاع',
        maint_return: 'اعادة صيانة',
        system_modules: 'اقسام النظام',
        customers: 'العملاء',
        quotations: 'عروض السعر',
        accounting: 'المحاسبة',
        envintory: 'المخزون',
        reports: 'التقارير',
        settings: 'الاعدادات',
        logout: 'تسجيل الخروج',
        invoice_notes: 'ملاحظات الفاتورة',
        add_customer: 'اضافة عميل',
        additional_mobile: 'الرقم الاضافي',
        client_statement: 'كشف حساب',
        mobil_10_digits: 'رقم الجوال يجب ان يكون عشرة ارقام',
        customer_added: 'تمت اضافة العميل بنجاح',
        add_quotation: 'اضافة عرض ',
        quotnumber: 'رقم العرض',
        salesman: 'البائع',
        quot_date: 'تاريخ العرض',
        quotation_details: 'تفاصيل العرض',
        delete: 'حذف',
        company_client: 'عميل شركة',
        can_not_add_empty_row: 'لا يمكن اضافة صنف فارغ',
        please_add_company_info: 'يرجى اضافة بيانات الشركة',
        quotation_added: 'تمت اضافة العرض بنجاح',
        can_not_add_row_without_discription: 'لا يمكن اضافة صنف بدون وصف',
        can_not_add_row_with_qtty: 'جيب ان تكون الكمية اكبر من 0',
        date: 'التاريخ',
        credit: 'دائن',
        depit: 'مدين',
        new_quotations: 'العروض الجديدة',
        invoiced_quots: 'العروض المفوترة',
        canceled_quots: 'العروض الملغية',
        quotation_no: 'رقم العرض',
        printQuot: 'طباعة العرض',
        cancel_quto: 'الغاء العرض',
        quot_time: 'توقيت العرض',
        print: 'طباعة',
        cash_total: 'المجموع النقدي',
        span_total: 'بطاقات البنك',
        voutcher_no: 'رقم السند',
        receive_from: 'استلام من',
        receive_by: 'الاستلام بواسطة',
        cash: 'نقدا',
        span: 'شبكة',
        received_voucher: 'سندات القبض',
        exchange_voucher: 'سندات الصرف',
        employees: 'الموظفين',
        recurring_expensess: 'مصاريف دورية',
        add_voutchre: 'اضافة سند',
        voutcher_resone: 'سبب السند',
        emp_name: 'اسم الموظف',
        id_number: 'رقم الهوية',
        basic_salary: 'الراتب الاساسي',
        home_allownance: 'بدل السكن',
        trans_allownance: 'بدل النقل',
        food_allown: 'بدل الطعام',
        other_allown: 'بدلات اخرى',
        goze_perc: 'التاميات',
        percentate: 'النسبة',
        update: 'تعديل',
        add_employee: 'اضافة موظف',
        add_expenses: 'اضافة مصروفات',
        expense_type: 'نوع المصروف',
        yearly: 'سنوي',
        midterm: 'نصف سنوي',
        quarterly: 'ربع سنوي',
        monthly: 'شهري',
        daily: 'يومي',
        expense_total: 'اجمالي المصروف',
        expense_period: 'مدة المصروف',
        calculation_will_be_done_automatic: 'Calculation values will be done automaticlly',
        office_rent: 'اجار المكتب',
        add_maint_request: 'اضافة امر تشغيل ',
        cardid: 'رقم البطاقة',
        received: 'الاستلام',
        delivered: 'التسليم',
        spare_partes: 'قطع الغيار',
        spare_parts: 'قطع الغيار',
        main_info: 'معلومات اساسية',
        received_date: 'تاريخ الاستلام',
        delivery_date: 'تاريخ التسليم',
        customer_info: 'معلومات العميل',
        company_info: 'معلومات الشركة',
        car_info: 'معلومات السيارة',
        received_status: 'حالة استلام السيارة كتابيا',
        work_description: 'وصف العمل',
        worker: "العمالة",
        advanced_payment: 'دفعة مقدمة',
        attachement: 'المرفقات',
        choose_file: 'اختر الملف',
        save: 'حفظ',
        additional_information: 'بيانات اضافية',
        work_description_required: 'وصف العمل مطلوب',
        added_sucess: 'تمت الاضافة بنجاح',
        add_user: 'إضافة مستخدم',
        update_user: 'تعديل مستخدم',
        users: 'المستخدمين',
        user_name: 'اسم الموظف',
        login_name: 'اسم الدخول',
        permission: 'الصلاحية',
        last_login: 'آخر دخول',
        save_login_info: 'حفظ بيانات الدخول',
        password: 'كلمة المرور',
        export_excel: 'تصدير اكسل',
        add_purcahse: 'اضافة مشتريات',
        vendor: 'المورد',
        purchase: 'المشتريات',
        inventory: 'المخزون',
        item_name: 'اسم الصنف',
        sales_invoices: 'فواتير المبيعات',
        sales: 'المبيعات',
        vat_required_return: 'قيمة الضريبة المضافة المستحقة (المستردة)',
        vat_report: 'تقرير الضريبة',
        dealy_report: 'تقرير اليومية',
        card_report: 'تقرير البطاقات',
        sales_report: 'تقرير المبيعات',
        return_report: 'تقرير المرتجع',
        percentage_report: 'تقرير النسب',
        percentage: 'النسبة',
        income: 'الدخل',
        expenses: 'المصروفات',
        expense: 'المصروف',
        period_expenses: 'المصاريف الدورية',
        closed_by: 'اغلق بواسطة',
        return_by: 'مرتجع عن طريق',
        shortcust_name: 'الاسم المختصر',
        vat_number: 'الرقم الضريبي',
        system_settings: 'اعدادات النظام',
        welcometo: 'مرحبا ',
        added_date: 'تاريخ الاضافة',
        added_time: 'التوقيت',
        photo_gallary: 'معرض الصور',
        print_card: 'طباعة البطاقة',
        car_description: 'وصف السيارة',
        invoice_samary: 'ملخص البطاقة',
        others: 'أخرى',
        view_card: 'استعراض البطاقة',
        card_closed: 'تم اغلاق البطاقة',
        please_check_data: 'يرجى التحقق من البيانات',
        cant_close_card_when_it_not_fully_paid: 'يجب استكمال الدفعات قبل اغلاق البطاقة',
        card_saved: 'تم حفظ البطاقة',
        view_invoice  : 'استعراض الفاتورة',
        customerid: 'المعرف',
        purchase_invoice: 'فاتورة مشتريات',
        billno: 'رقم الفاتورة',
        vendor_name: 'اسم المورد',
        vendor_mobile: 'رقم المورد',
        vendor_vatids: 'الرقم الضريبي للمورد',
        vendor_address: 'عنوان المورد',
        type: 'النوع',
        bill_type: 'نوع الفاتورة',
        bill_detates: 'تفاصيل الفاتورة',
        item_unit: 'الوحدة',
        pace: 'حبه',
        close_card: 'اغلاق البطاقة',
        chose_file: 'اختر الملف',
        upload: 'تحميل',
        full_name: 'الاسم كاملا',
        total_amount: 'اجمالي المبلغ',
        buy_goods: 'شراء بضاعة',
        sallary: 'راتب',
        buy_devices: 'شراء اجهزة',
        pay_for_bills: 'دفع فواتير',
        added_successfuly: 'تمت الاضافة بنجاح',
        nationality: 'الجنسية',
        percent: 'النسبة',
        group_name: 'اسم المجموعة',
        permissions: 'الصلاحيات',
        administratior: 'الادارة العامة',
        full_permissions: 'صلاحيات كاملة',
        accountant: 'المحاسبة',
        accountatn_permissions: 'كل الصلاحيات بدون الاعدادات',
        cashier: 'الكاشير',
        cashier_permissions: 'الفواتير والبطاقات',
        choose_employee: 'اختر الموظف',
        employees_and_users: 'الموظفين',
        salary_table: 'مسير الرواتب',
        projectname: 'اسم المشروع',
        inprogress: 'تحت العمل',
        new: 'جديد',
        closed: 'مغلق',
        not_invoiced: 'غير مفوتر',
        added_by: 'بواسطة',
        vendorid: 'معرف المورد',
        paid_in_advanced: 'السلف',
        paid_in_advanced_amount: 'قيمة السلفة',
        installment_number: 'عدد الدفعات',
        installment_amount: 'قيمةالدفعة',
        mor_than_zero: 'يرجى اضافة رقم اكبر من الصفر',
        emp_reward: 'المكافآت',
        emp_deducts: 'الخصومات',
        reward_amount: 'قيمة المكافأة',
        reward_resone: 'سبب المكافأة',
        deducts_amount: 'قيمة الخصم',
        deducts_resone: 'سبب الخصم',
        canceled: 'ملغي ',
        notpaid: 'لم يتم السداد',
        emp_custody: 'العهد',
        custody_amount: 'مبلغ العهدة',
        workstartdate: 'تاريخ بدء العمل',
        fix_salary: 'اصدار الراتب',
        card_expenses: 'مصروفات الصيانة من المخزون',
        invoice_expenses: 'مصاريف الفاتورة',
        expenses_summary: 'ملخص المصاريف',
        general_expenses: 'مصاريف عامة',
        cards_expenses: 'الصيانة',
        invoices_expenses: 'الفواتير',
        salaries: 'الرواتب',
        add_general_expense: ' مصروفات ',
        periodically: 'دورية',
        payit: 'سداد',
        pay_expenses: 'تسديد المصاريف',
        payment_period: 'مدة السداد',
        expense_amount: 'المبلغ',
        othermethod: "طرق دفع ", 
        invoices_total: 'اجمالي الفواتير', 
        invoices_remain: 'متبقي الفواتير', 
        other_expenses: 'مصاريف اخرى', 
        exp_total: 'اجمالي المصروفات', 
        exp_vat:'ضريبة المصروفات',
        building_no: 'رقم المبنى',
        street_name: 'اسم الشارع',
        branch_number: 'الرقم الفرعي',
        district: 'الحي',
        zipcode: 'الرمز البريدي',
        city:'المدينة',
        address: 'العنوان',
        update_customer: 'تعديل عميل',
        customer_updated: 'تم تعديل العميل',
        update_all: 'تعديل الكل',
        update_all_notes: 'تعديل الكل : تعديل كل الفواتير او سندات القبض بالبيانات الجديدة',
        curency: 'ريال',
        pos: 'نقطة بيع',
        cancel: 'الغاء',
        total_after_discount: 'اجمالي بعد الخصم',
        total_after_discountt: 'السعر بعد الخصم',
        multi_payment: 'الدفع المتعدد',
        paid_amount: 'المبلغ المدفوع',
        product_categories: 'أقسام المخزون',
        category_name: 'القسم',
        category_name_ar: 'اسم القسم عربي',
        category_name_en: 'اسم القسم انجليزي',
        category_type: 'نوع القسم',
        category_parent: 'القسم الرئيسي',
        subcat_count: 'الاقسام الفرعية',
        products: 'المنتجات', 
        add_category: 'اضافة قسم',
        main_cat: 'قسم رئيسي',
        sub_cat: 'قسم فرعي',
        income_report: 'تقرير الدخل',
        engine_meter: 'عداد السيارة (كم)',
        next_oil_change: 'تغيير الزيت بعد (كم)',
        crt_number: 'السجل التجاري',
      },     
    },
    activemenu: 'bill',
    loginRedirect: '',
    menu: {
      nsmall:{
        dashboard: {
          main: {
            index: 1,
            arname: 'الإدارة',
            enname: 'Managment',
            href: '/dashboard',
            to: '/dashboard',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 1,
              arname: 'الإدارة',
              enname: 'Management',
              href:'/dashboard',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crmx']
            },
          ]
        },
        customers: {
          main: {
            index: 2,
            arname: 'العملاء',
            enname: 'Customers',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:2,
              main: 2,
              arname: 'التقويم',
              enname: 'Calendar',
              href:'/CRMCalendar',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crmx']
            },
            {
              index:10,
              main: 2,
              arname: 'المشاريع',
              enname: 'Project',
              href:'/Project',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:8,
              main: 2,
              arname: 'قائمة العملاء',
              enname: 'Dealing',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 2,
              arname: 'عرض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            // {
            //   index:1,
            //   main: 1,
            //   arname: 'تحت التفاوض',
            //   enname: 'Dealing',
            //   href:'/newCRM',
            //   class:'',
            //   // style: 'background:green !important;color:#fff !important;',
            //   style: '',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   perms: ['crm']
            // },
            {
              index:7,
              main: 2,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/hCalendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:7,
              main: 2,
              arname: 'العروض',
              enname: 'Offers',
              href:'/Offers',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:7,
              main: 2,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['cars']
            },
            {
              index:7,
              main: 2,
              arname: 'المهام',
              enname: 'tasks',
              href:'/tasks',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['carssss']
            },
            
            // {
            //   index:8,
            //   main: 1,
            //   arname: 'مراجعة العملاء',
            //   enname: 'Client Review',
            //   href:'/billClients',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['crm']
            // },
            {
              index:9,
              main: 2,
              arname: 'ارسال واتس',
              enname: 'Send WhatsApp',
              href:'/WhatsApp',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['crm']
            },
            
            {
              index:7,
              main: 2,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['carsss']
            },
          ]
        },
        sales: {
          main: {
            index: 3,
            arname: 'المبيعات',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            // {
            //   index:7,
            //   main: 1,
            //   arname: 'العملاء',
            //   enname: 'Customers',
            //   href:'/clientList',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   perms: ['any']
            // },
            {
              index:8,
              main: 3,
              arname: 'العملاء الحاليين',
              enname: 'Client Review',
              href:'/bill_clients', 
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['crm']
            },
            {
              index:10,
              main: 3,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:10,
              main: 3,
              arname: 'التجديد',
              enname: 'Renewal',
              href:'/renewal',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:1,
              main: 3,
              arname: 'الفواتير',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:1,
              main: 3,
              arname: 'نقطة البيع',
              enname: 'POS',
              href:'/POS-RETAIL',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['POSREST']
            },
            {
              index:2,
              main: 3,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['cars']
            },
            // {
            //   index:7,
            //   main: 2,
            //   arname: 'عرض السعر',
            //   enname: 'Quotations',
            //   href:'/quotations/n/1',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   perms: ['any']
            // },
            {
              index:8,
              main: 3,
              arname: 'التحصيل',
              enname: 'Settings',
              href:'/invoices-not-paid',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            
          ]
        },
        accounting: {
          main: {
            index:4,
            arname: 'المحاسبة',
            enname: 'Accounting',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [

            {
              index:4,
              main: 4,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'المشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 4,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main: 4,
              arname: 'انواع المصروفات',
              enname: 'Expenses Types',
              href:'/settings/expensesTypes',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            {
              index:5,
              main: 4,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            // {
            //   index:8,
            //   main: 2,
            //   arname: 'طباعة سند قبض',
            //   enname: 'ٌReceipt Voucher',
            //   href:'/finance/inreceipt',
            //   class:'',
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2],
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:9,
              main: 4,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 5,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['anyss']
            },
            // {
            //   index:2,
            //   main: 3,
            //   arname: 'تقرير النسب',
            //   enname: 'Precentage Report',
            //   href:'/reports/percent-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: `'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:3,
            //   main: 4,
            //   arname: 'تقرير التأمينات',
            //   enname: 'GOZI Report',
            //   href:'/emps/gozi-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
          ]
        },
        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: ' اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            // {
            //   index:1,
            //   main: 2,
            //   arname: 'تقرير اليومية',
            //   enname: 'Income Report',
            //   href:'/reports/income-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:2,
              main: 6,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            // {
            //   index:3,
            //   main: 2,
            //   arname: 'تقرير المبيعات',
            //   enname: 'Sales Report',
            //   href:'/reports/sales-reports',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:4,
            //   main: 2,
            //   arname: 'تقرير المرتجع',
            //   enname: 'Return Report',
            //   href:'/reports/return-reports',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:5,
              main: 6,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['carss']
            },
            { 
              index:5,
              main: 6,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report-contract',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            // {
            //   index:6,
            //   main: 2,
            //   arname: 'كشف حساب',
            //   enname: 'Statement',
            //   href:'/reports/statment-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:7,
            //   main: 4,
            //   arname: 'تقرير النسب',
            //   enname: 'Percent Report',
            //   href:'/reports/percent-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:7,
            //   main: 4,
            //   arname: 'تقرير الرواتب',
            //   enname: 'Salary Report',
            //   href:'/reports/salary-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            
          ]
        },
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'اعدادات الواتس',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            // {
            //   index:4,
            //   main: 3,
            //   arname: 'الموظفين',
            //   enname: 'Employees',
            //   href:'/settings/employees',
            //   class:'mytab4',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2],
            //   style: '',
            //   perms: ['onlySalesPurchase']
            // },
            
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        training: {
          main: {
            index: 100,
            arname: 'التدريب',
            enname: 'training',
            href: '',
            to: '',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff',
            frame: 'iframe',
            border: '#000',
            perms: ['any']
          },
          sub: []
        },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      smallif:{
        customers: {
          main: {
            index: 1,
            arname: 'العملاء',
            enname: 'Customers',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 1,
              arname: 'التقويم',
              enname: 'Calendar',
              href:'/CRMCalendar',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['crmx']
            },
            {
              index:10,
              main: 1,
              arname: 'المشاريع',
              enname: 'Project',
              href:'/Project',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:8,
              main: 1,
              arname: 'العملاء',
              enname: 'Customers',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[3],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تحت التفاوض',
              enname: 'Dealing',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 1,
              arname: 'عرض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[3],
              perms: ['any']
            },
            {
              index:9,
              main: 1,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[3],
              perms: ['any']
            },
            // {
            //   index:1,
            //   main: 1,
            //   arname: 'تحت التفاوض',
            //   enname: 'Dealing',
            //   href:'/newCRM',
            //   class:'',
            //   // style: 'background:green !important;color:#fff !important;',
            //   style: '',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   perms: ['crm']
            // },
            {
              index:7,
              main: 1,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/hCalendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:7,
              main: 1,
              arname: 'العروض',
              enname: 'Offers',
              href:'/Offers',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:7,
              main: 1,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['cars']
            },
            {
              index:7,
              main: 1,
              arname: 'المهام',
              enname: 'tasks',
              href:'/tasks',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['cars']
            },
            
            // {
            //   index:8,
            //   main: 1,
            //   arname: 'مراجعة العملاء',
            //   enname: 'Client Review',
            //   href:'/billClients',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['crm']
            // },
            {
              index:9,
              main: 1,
              arname: 'ارسال واتس',
              enname: 'Send WhatsApp',
              href:'/WhatsApp',
              class:'mytab4',
              groupid:[1,2,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['crm']
            },
            
            {
              index:7,
              main: 1,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['cars']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'المبيعات',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            // {
            //   index:7,
            //   main: 1,
            //   arname: 'العملاء',
            //   enname: 'Customers',
            //   href:'/clientList',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   perms: ['any']
            // },
            {
              index:8,
              main: 2,
              arname: 'العملاء الحاليين',
              enname: 'Client Review',
              href:'/bill_clients', 
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['crm']
            },
            {
              index:10,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:10,
              main: 2,
              arname: 'التجديد',
              enname: 'Renewal',
              href:'/renewal',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:1,
              main: 2,
              arname: 'الفواتير',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:1,
              main: 2,
              arname: 'نقطة البيع',
              enname: 'POS',
              href:'/POS-RETAIL',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['POSREST']
            },
            {
              index:2,
              main: 2,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['cars']
            },
            // {
            //   index:7,
            //   main: 2,
            //   arname: 'عرض السعر',
            //   enname: 'Quotations',
            //   href:'/quotations/n/1',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   perms: ['any']
            // },
            
          ]
        },
        accounting: {
          main: {
            index: 3,
            arname: 'المحاسبة',
            enname: 'Accounting',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [

            {
              index:4,
              main: 3,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 3,
              arname: 'المشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 3,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 3,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main: 3,
              arname: 'انواع المصروفات',
              enname: 'Expenses Types',
              href:'/settings/expensesTypes',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            {
              index:5,
              main: 3,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            // {
            //   index:8,
            //   main: 2,
            //   arname: 'طباعة سند قبض',
            //   enname: 'ٌReceipt Voucher',
            //   href:'/finance/inreceipt',
            //   class:'',
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2],
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:9,
              main: 3,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 3,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 4,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 4,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 4,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 4,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 4,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 4,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            // {
            //   index:2,
            //   main: 3,
            //   arname: 'تقرير النسب',
            //   enname: 'Precentage Report',
            //   href:'/reports/percent-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: `'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:3,
            //   main: 4,
            //   arname: 'تقرير التأمينات',
            //   enname: 'GOZI Report',
            //   href:'/emps/gozi-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
          ]
        },
        reports: {
          main: {
            index: 5,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: ' اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            // {
            //   index:1,
            //   main: 2,
            //   arname: 'تقرير اليومية',
            //   enname: 'Income Report',
            //   href:'/reports/income-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:2,
              main: 5,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            // {
            //   index:3,
            //   main: 2,
            //   arname: 'تقرير المبيعات',
            //   enname: 'Sales Report',
            //   href:'/reports/sales-reports',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:4,
            //   main: 2,
            //   arname: 'تقرير المرتجع',
            //   enname: 'Return Report',
            //   href:'/reports/return-reports',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:5,
              main: 5,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['carss']
            },
            { 
              index:5,
              main: 5,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report-contract',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            // {
            //   index:6,
            //   main: 2,
            //   arname: 'كشف حساب',
            //   enname: 'Statement',
            //   href:'/reports/statment-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:7,
            //   main: 4,
            //   arname: 'تقرير النسب',
            //   enname: 'Percent Report',
            //   href:'/reports/percent-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:7,
            //   main: 4,
            //   arname: 'تقرير الرواتب',
            //   enname: 'Salary Report',
            //   href:'/reports/salary-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            
          ]
        },
        settings: {
          main: {
            index: 6,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 6,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 6,
              arname: 'اعدادات الواتس',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            // {
            //   index:4,
            //   main: 3,
            //   arname: 'الموظفين',
            //   enname: 'Employees',
            //   href:'/settings/employees',
            //   class:'mytab4',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2],
            //   style: '',
            //   perms: ['onlySalesPurchase']
            // },
            
            {
              index:6,
              main: 6,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 6,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        training: {
          main: {
            index: 100,
            arname: 'التدريب',
            enname: 'training',
            href: '',
            to: '',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff',
            frame: 'iframe',
            border: '#000',
            perms: ['any']
          },
          sub: []
        },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      erp1:{
        customers: {
          main: {
            index: 1,
            arname: 'المبيعات',
            enname: 'Sales',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:2,
              main: 1,
              arname: 'المشاريع',
              enname: 'Projects',
              href:'/CRMCalendar',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['crm']
            },
            {
              index:4,
              main: 1,
              arname: 'العملاء المحتملين',
              enname: 'Leads',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:3,
              main: 1,
              arname: 'العملاء',
              enname: 'Customers',
              href:'/projects',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:5,
              main: 1,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/bill_clients',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:6,
              main: 1,
              arname: 'التقارير',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            
          ]
        },
        accounting: {
          main: {
            index: 2,
            arname: 'المحاسبة',
            enname: 'Accounting',
            href: '/finance/coa',
            to: '/finance/coa',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 2,
              arname: 'شجرة الحسابات',
              enname: 'Chart Of Account',
              href:'/finance/coa',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 2,
              arname: 'القيود',
              enname: 'JVs',
              href:'/finance/jvs',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'أرصدة افتتاحية',
              enname: 'Opening Balance',
              href:'/finance/fient',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 2,
              arname: 'طرق الدفع',
              enname: 'Payment Types',
              href:'/finance/paytypes',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 2,
              arname: 'الأصول الثابتة',
              enname: 'Fixed Assets',
              href:'/finance/fixed-assets',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 2,
              arname: 'ربط الدليل',
              enname: 'Integrations',
              href:'/finance/definition',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 2,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 2,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:9,
              main: 2,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        store: {
          main: {
            index: 3,
            arname: 'المخزون',
            enname: 'Inventory',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 3,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:2,
              main: 3,
              arname: 'المستودعات',
              style: '',
              enname: 'Stores',
              href:'/stores',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:3,
              main: 3,
              arname: 'تحويل مخزون',
              style: '',
              enname: 'Transfer Inevent',
              href:'/stores-transfer',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 3,
              arname: ' الأرصدة الافتتاحية',
              style: '',
              enname: 'Start Inventory',
              href:'/start-Inventory',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any33']
            },
            {
              index:5,
              main: 3,
              arname: 'المشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:6,
              main: 3,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:7,
              main: 3,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 4,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 4,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 4,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 4,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 4,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
          ]
        },
        reports: {
          main: {
            index: 5,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: ' اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 5,
              arname: 'كشف حساب',
              enname: 'Statements',
              href:'/reports/statment-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 5,
              arname: 'ميزان المراجعة',
              enname: 'Trial Balance',
              href:'/reports/balance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'الارباح والخسائر',
              enname: 'PNL Report',
              href:'/reports/bnl-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 5,
              arname: 'الميزانية',
              enname: 'Balance Report',
              href:'/reports/acbalance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 5,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:8,
              main: 5,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:9,
              main: 5,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:10,
              main: 5,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars2']
            },
            {
              index:11,
              main: 5,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report-contract',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['carsd']
            },
            {
              index:5,
              main: 5,
              arname: 'تقرير المشرف',
              enname: 'Supervisor Report',
              href:'/supervisor-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 5,
              arname: 'الاكثر مبيعا',
              enname: 'Most Sales',
              href:'/report/items-max',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 5,
              arname: 'الاقل مبيعا',
              enname: 'Most Sales',
              href:'/report/ititems-less',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
          ]
        },
        settings: {
          main: {
            index: 6,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 6,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:4,
              main: 6,
              arname: 'انواع المصروفات',
              enname: 'Expenses Types',
              href:'/settings/expensesTypes',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            
            {
              index:5,
              main: 6,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        training: {
          main: {
            index: 100,
            arname: 'التدريب',
            enname: 'training',
            href: '',
            to: '',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff',
            frame: 'iframe',
            border: '#000',
            perms: ['any']
          },
          sub: []
        },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      fatora:{
        customers: {
          main: {
            index: 1,
            arname: 'العملاء',
            enname: 'Customers',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 1,
              arname: 'قائمة العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'قائمة العملاء',
              enname: 'Clients',
              href:'/clientList-mobile',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[9],
              perms: ['any']
            },
            {
              index:7,
              main: 1,
              arname: 'عرض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'المبيعات',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/invoicelist',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 2,
              arname: 'الفواتير',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:2,
              main: 2,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            
          ]
        },
        purchase: {
          main: {
            index: 3,
            arname: 'المشتريات',
            enname: 'Purchase',
            href: '/purchase',
            to: '/purchase',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 3,
              arname: 'المشتريات',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:1,
              main: 3,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            
          ]
        },
        reports: {
          main: {
            index: 4,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/income-report',
            to: '/income-report',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 4,
              arname: 'تقرير اليومية',
              enname: 'Daily Report',
              href:'/income-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:1,
              main: 4,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 4,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:6,
              main: 4,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['showExpenseReport']
            },
          ]
        },
        settings: {
          main: {
            index: 6,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            // {
            //   index:3,
            //   main: 6,
            //   arname: 'الصلاحيات',
            //   enname: 'Permissions',
            //   href:'/settings/permissions-settings',
            //   style: '',
            //   class:'mytab4',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1],
            //   perms: ['any']
            // },
            // {
            //   index:3,
            //   main: 6,
            //   arname: 'اعدادات الواتس',
            //   enname: 'WhatsApp Settings',
            //   href:'/settings-whatsapp',
            //   style: '',
            //   class:'mytab4',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1],
            //   perms: ['any']
            // },
            {
              index:5,
              main: 6,
              arname: 'انواع المصروفات',
              enname: 'Expenses Types',
              href:'/settings/expensesTypes',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            {
              index:6,
              main: 6,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 6,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        training: {
          main: {
            index: 100,
            arname: 'التدريب',
            enname: 'training',
            href: '',
            to: '',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff',
            frame: 'iframe',
            border: '#000',
            perms: ['any']
          },
          sub: []
        },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      erp:{
        customers: {
          main: {
            index: 1,
            arname: 'العملاء',
            enname: 'Customers',
            href: '/sales',
            to: '/sales',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:10,
              main: 1,
              arname: 'تحت التفاوض',
              enname: 'Dealing',
              href:'/sales',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['sales_crm']
            },
            {
              index:9,
              main: 1,
              arname: 'الحملات الاعلانية',
              enname: 'Marketing',
              href:'/whatsSet',
              class:'mytab4',
              groupid:[1,2,3,4,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['sales_crm']
            },
            // {
            //   index:9,
            //   main: 9,
            //   arname: 'اعدادات الواتس',
            //   enname: 'WhatsApp Settings',
            //   href:'/settings',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['isBill']
            // },
            {
              index:9,
              main: 1,
              arname: 'ارسال واتس',
              enname: 'Send WhatsApp',
              href:'/WhatsApp',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['isBill']
            },
            // {
            //   index:1,
            //   main: 9,
            //   arname: 'التقويم',
            //   enname: 'Calendar',
            //   href:'/CRMCalendar',
            //   class:'',
            //   // style: 'background:green !important;color:#fff !important;',
            //   style: '',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['anysss']
            // },
            {
              index:8,
              main: 1,
              arname: 'قائمة العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'قائمة العملاء',
              enname: 'Clients',
              href:'/clientList-mobile',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[9],
              perms: ['any']
            },
            
            {
              index:7,
              main: 1,
              arname: 'عرض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            // {
            //   index:2,
            //   main: 1,
            //   arname: 'عقود الصيانة',
            //   enname: 'Maint. Contracts',
            //   href:'/maintContracts',
            //   style: '', 
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['car_contract']
            // },
            {
              index:2,
              main: 1,
              arname: 'أمر تشغيل',
              enname: 'Job Card',
              href:'/cars-cards',
              style: '', 
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['car_contract']
            },
            // {
            //   index:1,
            //   main: 9,
            //   arname: 'تحت التفاوض',
            //   enname: 'Dealing',
            //   href:'/newCRM',
            //   class:'',
            //   // style: 'background:green !important;color:#fff !important;',
            //   style: '',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['crm']
            // },
            {
              index:7,
              main: 1,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/hCalendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['crm1']
            },
            {
              index:7,
              main: 1,
              arname: 'العروض',
              enname: 'Offers',
              href:'/Offers',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['crm1']
            },
            {
              index:7,
              main: 1,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['cars22']
            },
            // {
            //   index:7,
            //   main: 9,
            //   arname: 'المهام',
            //   enname: 'tasks',
            //   href:'/tasks',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['cars']
            // },
            
            // {
            //   index:8,
            //   main: 1,
            //   arname: 'مراجعة العملاء',
            //   enname: 'Client Review',
            //   href:'/billClients',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['crm']
            // },
            
            {
              index:9,
              main: 1,
              arname: 'ارسال واتس',
              enname: 'Send WhatsApp',
              href:'/WhatsApp',
              class:'mytab4',
              groupid:[1,2,3,4,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['crm4']
            },
            
            {
              index:7,
              main: 1,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['cars4']
            },
            // {
            //   index:9,
            //   main: 9,
            //   arname: 'العروض',
            //   enname: 'Offers',
            //   href:'/offers',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:9,
              main: 1,
              arname: 'تقارير الواتس',
              enname: 'WhatsApp Report',
              href:'/whatsReport',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['isBillx']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'المبيعات',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 2,
              arname: 'العملاء الحاليين',
              enname: 'Current Clients',
              href:'/bill_clients',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['isBill']
            },
            {
              index:4,
              main: 2,
              arname: 'الفواتير',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:3,
              main: 2,
              arname: 'التجديد',
              enname: 'Renewal',
              href:'/renewal',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['samcrm']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['samcrm3']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/whats-support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['isBill']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['isBillx']
            },
            {
              index:2,
              main: 2,
              arname: 'عقود الصيانة',
              enname: 'Maint. Contracts',
              href:'/maintContracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['car_contract']
            },
            
            
            {
              index:5,
              main: 2,
              arname: 'نقطة البيع',
              enname: 'POS',
              href:'/POS-RETAIL',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['POSREST']
            },
            {
              index:6,
              main: 2,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            {
              index:7,
              main: 2,
              arname: 'عرض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['anys']
            },
            
          ]
        },
        
        accounting: {
          main: {
            index: 3,
            arname: 'المحاسبة',
            enname: 'Accounting',
            href: '/finance/coa',
            to: '/finance/coa',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 3,
              arname: 'شجرة الحسابات',
              enname: 'Chart Of Account',
              href:'/finance/coa',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 3,
              arname: 'القيود',
              enname: 'JVs',
              href:'/finance/jvs',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 3,
              arname: 'أرصدة افتتاحية',
              enname: 'Opening Balance',
              href:'/finance/fient',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 3,
              arname: 'طرق الدفع',
              enname: 'Payment Types',
              href:'/finance/paytypes',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 3,
              arname: 'الأصول الثابتة',
              enname: 'Fixed Assets',
              href:'/finance/fixed-assets',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 3,
              arname: 'ربط الدليل',
              enname: 'Integrations',
              href:'/finance/definition',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main:3,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 3,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 3,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        store: {
          main: {
            index: 4,
            arname: 'المخزون',
            enname: 'Inventory',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 4,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'المستودعات',
              style: '',
              enname: 'Stores',
              href:'/stores',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'الوحدات',
              style: '',
              enname: 'Units',
              href:'/inventory/units',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تحويل مخزون',
              style: '',
              enname: 'Transfer Inevent',
              href:'/stores-transfer',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: ' الأرصدة الافتتاحية',
              style: '',
              enname: 'Start Inventory',
              href:'/start-Inventory',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any33']
            },
            {
              index:4,
              main: 4,
              arname: 'المشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 4,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 5,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main:5,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
          ]
        },
        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: ' اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 6,
              arname: 'كشف حساب',
              enname: 'Statements',
              href:'/reports/statment-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 6,
              arname: 'ميزان المراجعة',
              enname: 'Trial Balance',
              href:'/reports/balance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'الارباح والخسائر',
              enname: 'PNL Report',
              href:'/reports/bnl-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الميزانية',
              enname: 'Balance Report',
              href:'/reports/acbalance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 6,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:8,
              main: 6,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المشرف',
              enname: 'Supervisor Report',
              href:'/supervisor-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 6,
              arname: 'الاكثر مبيعا',
              enname: 'Most Sales',
              href:'/pos/report/items-max',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 6,
              arname: 'الاقل مبيعا',
              enname: 'Most Sales',
              href:'/pos/report/ititems-less',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
          ]
        },
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'اعدادات الواتس',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:5,
              main: 7,
              arname: 'انواع المصروفات',
              enname: 'Expenses Types',
              href:'/settings/expensesTypes',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        logout: {
          main: {
            index: 55,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8,9],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      erpsam3:{
        customers: {
          main: {
            index: 1,
            arname: 'العملاء',
            enname: 'Customers',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 1,
              arname: 'العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 1,
              arname: 'عروض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:10,
              main: 1,
              arname: 'تحت التفاوض',
              enname: 'Dealing',
              // href:'/sales',
              href:'/sales',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['sales_crm1']
            },
            {
              index:10,
              main: 1,
              arname: 'الصيانة الدورية',
              enname: 'Recuring Maint',
              href:'/periodic-maint',
              // href:'',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showPeriodMaint']
            },
            {
              index:7,
              main: 1,
              arname: 'برامج صيانة',
              enname: 'Offers',
              href:'/Offers',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['showOffering']
            },
            {
              index:7,
              main: 1,
              arname: 'عروض سنوية',
              enname: 'Subscriber',
              href:'/subscriber',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['showSubscriber']
            },
            {
              index:10,
              main: 1,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:10,
              main: 1,
              arname: 'صيانة خارجية',
              enname: 'External Maint.',
              href:'/external-maint',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showExMaint1']
            },
            {
              index:10,
              main: 1,
              arname: 'عقود صيانة',
              enname: 'Maint. Contract',
              href:'/maintContracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
            {
              index:9,
              main: 1,
              arname: 'الحملات الاعلانية',
              enname: 'Marketing',
              href:'/WhatsApp',
              class:'mytab4',
              groupid:[1,2,3,4,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showWhatsMarket']
            },
            
            // {
            //   index:9,
            //   main: 9,
            //   arname: 'اعدادات الواتس',
            //   enname: 'WhatsApp Settings',
            //   href:'/settings',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['isBill']
            // },
            {
              index:9,
              main: 1,
              arname: 'ارسال واتس',
              enname: 'Send WhatsApp',
              href:'/WhatsApp',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['isBill']
            },
            {
              index:9,
              main: 1,
              arname: 'الكول سنتر',
              enname: 'Call Center',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showCallCenter']
            },
            
            // {
            //   index:1,
            //   main: 9,
            //   arname: 'التقويم',
            //   enname: 'Calendar',
            //   href:'/CRMCalendar',
            //   class:'',
            //   // style: 'background:green !important;color:#fff !important;',
            //   style: '',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['anysss']
            // },
            
            {
              index:8,
              main: 1,
              arname: 'قائمة العملاء',
              enname: 'Clients',
              href:'/clientList-mobile',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[9],
              perms: ['any']
            },
            
            
            // {
            //   index:2,
            //   main: 1,
            //   arname: 'عقود الصيانة',
            //   enname: 'Maint. Contracts',
            //   href:'/maintContracts',
            //   style: '', 
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['car_contract']
            // },
            {
              index:2,
              main: 1,
              arname: 'أمر تشغيل',
              enname: 'Job Card',
              href:'/cars-cards',
              style: '', 
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['car_contract']
            },
            // {
            //   index:1,
            //   main: 9,
            //   arname: 'تحت التفاوض',
            //   enname: 'Dealing',
            //   href:'/newCRM',
            //   class:'',
            //   // style: 'background:green !important;color:#fff !important;',
            //   style: '',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['crm']
            // },
            {
              index:7,
              main: 1,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/hCalendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['crm1']
            },
            {
              index:7,
              main: 1,
              arname: 'العروض',
              enname: 'Offers',
              href:'/Offers',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['crm1']
            },
            {
              index:7,
              main: 1,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['cars22']
            },
            // {
            //   index:7,
            //   main: 9,
            //   arname: 'المهام',
            //   enname: 'tasks',
            //   href:'/tasks',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['cars']
            // },
            
            // {
            //   index:8,
            //   main: 1,
            //   arname: 'مراجعة العملاء',
            //   enname: 'Client Review',
            //   href:'/billClients',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['crm']
            // },
            
            {
              index:9,
              main: 1,
              arname: 'ارسال واتس',
              enname: 'Send WhatsApp',
              href:'/WhatsApp',
              class:'mytab4',
              groupid:[1,2,3,4,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['crm4']
            },
            
            {
              index:7,
              main: 1,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['cars4']
            },
            // {
            //   index:9,
            //   main: 9,
            //   arname: 'العروض',
            //   enname: 'Offers',
            //   href:'/offers',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:9,
              main: 1,
              arname: 'تقارير الواتس',
              enname: 'WhatsApp Report',
              href:'/whatsReport',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['isBillx']
            },
            {
              index:9,
              main: 1,
              arname: 'الكول سنتر',
              enname: 'Call Center',
              href:'/call-center',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showCallCenter']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'المبيعات',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 2,
              arname: 'العملاء الحاليين',
              enname: 'Current Clients',
              href:'/bill_clients',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['isBill']
            },
            {
              index:4,
              main: 2,
              arname: 'الفواتير',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:3,
              main: 2,
              arname: 'التجديد',
              enname: 'Renewal',
              href:'/renewal',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['samcrm']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['samcrm3']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/whats-support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['isBill']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['isBillx']
            },
            {
              index:2,
              main: 2,
              arname: 'صيانة دورية',
              enname: 'Maint. Contracts',
              href:'',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['car_contract']
            },
            {
              index:5,
              main: 2,
              arname: 'نقطة البيع',
              enname: 'POS',
              href:'/POS-RETAIL',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['POSREST']
            },
            {
              index:6,
              main: 2,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            {
              index:7,
              main: 2,
              arname: 'عرض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['anys']
            },
            {
              index:2,
              main: 2,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            
            {
              index:7,
              main: 2,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:5,
              main: 2,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            
            
          ]
        },
        accounting: {
          main: {
            index: 3,
            arname: 'المحاسبة',
            enname: 'Accounting',
            href: '/finance/coa',
            to: '/finance/coa',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 3,
              arname: 'شجرة الحسابات',
              enname: 'Chart Of Account',
              href:'/finance/coa',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 3,
              arname: 'القيود',
              enname: 'JVs',
              href:'/finance/jvs',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 3,
              arname: 'أرصدة افتتاحية',
              enname: 'Opening Balance',
              href:'/finance/fient',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 3,
              arname: 'طرق الدفع',
              enname: 'Payment Types',
              href:'/finance/paytypes',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 3,
              arname: 'الأصول الثابتة',
              enname: 'Fixed Assets',
              href:'/finance/fixed-assets',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 3,
              arname: 'ربط الدليل',
              enname: 'Integrations',
              href:'/finance/definition',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main:3,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 3,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 3,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        store: {
          main: {
            index: 4,
            arname: 'المخزون',
            enname: 'Inventory',
            href: '/stores',
            to: '/stores',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 4,
              arname: 'المستودعات',
              style: '',
              enname: 'Stores',
              href:'/stores',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'الوحدات',
              style: '',
              enname: 'Units',
              href:'/inventory/units',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تحويل مخزون',
              style: '',
              enname: 'Transfer Inevent',
              href:'/stores-transfer',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: ' الأرصدة الافتتاحية',
              style: '',
              enname: 'Start Inventory',
              href:'/start-Inventory',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 4,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تقرير الجرد',
              style: '',
              enname: 'Inventory Report',
              href:'/inventory-reports',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main: 4,
              arname: 'الاكثر مبيعا',
              enname: 'Most Sales',
              href:'/report/items-max',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 4,
              arname: 'الاقل مبيعا',
              enname: 'Less Sales',
              href:'/report/items-less',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
          ]
        },
        purchase: {
          main: {
            index: 12,
            arname: 'المشتريات',
            enname: 'Inventory',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 12,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'أمر شراء',
              style: '',
              enname: 'Purchase',
              href:'/purchase-order',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'المشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'مرتجع المشتريات',
              style: '',
              enname: 'purchase Return',
              href:'/purchaseReturn',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },

            {
              index:4,
              main: 12,
              arname: 'تقرير المشتريات',
              style: '',
              enname: 'Purchase Report',
              href:'/purchase-report',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 5,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main:5,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير النسب',
              enname: 'Percentage Report',
              href:'/reports/percent-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير الاجازات',
              enname: 'Vacation Report',
              href:'/reports/vacation_report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'تقرير المشرف',
              enname: 'Supervisor Report',
              href:'/supervisor-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            
          ]
        },
        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: 'تقرير اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 6,
              arname: 'كشف حساب',
              enname: 'Statements',
              href:'/reports/statment-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 6,
              arname: 'ميزان المراجعة',
              enname: 'Trial Balance',
              href:'/reports/balance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'الارباح والخسائر',
              enname: 'PNL Report',
              href:'/reports/bnl-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الميزانية',
              enname: 'Balance Report',
              href:'/reports/acbalance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'تقرير الدليل',
              enname: 'COA Report',
              href:'/coa-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الدليل بالمجاميع',
              enname: 'COA Totals Report',
              href:'/coa-reports-totals',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 6,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:8,
              main: 6,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            
          ]
        },
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'اعدادات الواتس',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:5,
              main: 7,
              arname: 'انواع المصروفات',
              enname: 'Expenses Types',
              href:'/settings/expensesTypes',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        training: {
          main: {
            index: 100,
            arname: 'التدريب',
            enname: 'training',
            href: '',
            to: '',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff',
            frame: 'iframe',
            border: '#000',
            perms: ['any']
          },
          sub: []
        },
        logout: {
          main: {
            index: 55,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      erpsam:{ 
        dashboard: {
          main: {
            index: 1,
            arname: 'الادارة',
            enname: 'Management',
            href: '/dashboard',
            to: '/dashboard',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 1,
              arname: 'تقارير بيانية',
              enname: 'Management',
              href:'/dashboard',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التعميدات',
              enname: 'Management',
              href:'/dashboard33',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'الاصول الثابتة',
              enname: 'Fixed Assets',
              href:'/management/fixed-assets',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير اليومية',
              enname: 'Daily Report',
              href:'/reports/income-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'المشتريات',
              enname: 'Purchase',
              href:'/mg-purchase-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الاداء',
              enname: 'KPI Report',
              href:'/reports/kpi-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'عروض تسويقية',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'العقود',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التحصيل',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            
            
          ]
        },
        customers: {
          main: {
            index: 88,
            arname: 'المبيعات',
            enname: 'Customers',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:7,
              main: 88,
              arname: 'اعدادات العروض',
              enname: 'Offers',
              href:'/Offers',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['showOffering']
            },
            {
              index:9,
              main: 88,
              arname: 'مركز الاتصال',
              enname: 'Call Center',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showCallCenter']
            },
            {
              index:9,
              main: 88,
              arname: 'التواصل الاجتماعي',
              enname: 'Social Media',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showCallCenter']
            },
            {
              index:8,
              main: 88,
              arname: 'العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 88,
              arname: 'عروض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            // {
            //   index:10,
            //   main: 88,
            //   arname: 'تقرير الضمان',
            //   enname: 'Recuring Maint',
            //   href:'/periodic-maint',
            //   // href:'',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['any']
            // },
            {
              index:10,
              main: 88,
              arname: 'الصيانة الدورية',
              enname: 'Recuring Maint',
              href:'/periodic-maint',
              // href:'',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showPeriodMaint']
            },
            {
              index:10,
              main: 88,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:7,
              main: 88,
              arname: 'العروض التسويقية',
              enname: 'Subscriber',
              href:'/subscriber',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['showSubscriber']
            },
            
            
            {
              index:10,
              main: 88,
              arname: 'عقود صيانة',
              enname: 'Maint. Contract',
              href:'/maintContracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
            {
              index:10,
              main: 88,
              arname: 'عقود التأمين',
              enname: 'Insurance. Contract',
              href:'/insurance-contracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
           
            
            {
              index:7,
              main: 88,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['cars4']
            },
            
            
          ]
        },
        workJob: {
          main: {
            index: 99,
            arname: 'الصيانة',
            enname: 'Works',
            href: '/home',
            to: '/home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:6,
              main: 99,
              arname: 'الفحص',
              enname: 'Check Car',
              href:'',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            {
              index:10,
              main: 99,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            {
              index:2,
              main: 99,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:10,
              main: 99,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:10,
              main: 99,
              arname: 'صيانة خارجية',
              enname: 'External Maint.',
              href:'/external-maint',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 99,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:5,
              main: 99,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2,3,4,5,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'الفواتير',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/invoicelist',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 2,
              arname: 'العملاء الحاليين',
              enname: 'Current Clients',
              href:'/bill_clients',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['isBill']
            },
            {
              index:4,
              main: 2,
              arname: 'فاتورة مبيعات',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:4,
              main: 2,
              arname: 'فاتورة مشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main:2,
              arname: 'فاتورة مصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'التحصيل',
              enname: 'Renewal',
              href:'/invoices-not-paid',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'نقاط البيع',
              enname: 'POS',
              href:'/POS',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'التجديد',
              enname: 'Renewal',
              href:'/renewal',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['samcrm']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['samcrm3']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/whats-support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['isBill']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['isBillx']
            },
            {
              index:2,
              main: 2,
              arname: 'صيانة دورية',
              enname: 'Maint. Contracts',
              href:'',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['car_contract']
            },
            {
              index:5,
              main: 2,
              arname: 'نقطة البيع',
              enname: 'POS',
              // href:'/POS-RETAIL',
              href:'',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['POSREST']
            },
            {
              index:7,
              main: 2,
              arname: 'عرض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['anys']
            },
            {
              index:1,
              main: 2,
              arname: 'تقرير اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 2,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
          ]
        },
        
        accounting: {
          main: {
            index: 3,
            arname: 'المحاسبة',
            enname: 'Accounting',
            href: '/finance/coa',
            to: '/finance/coa',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 3,
              arname: 'شجرة الحسابات',
              enname: 'Chart Of Account',
              href:'/finance/coa',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 3,
              arname: 'القيود',
              enname: 'JVs',
              href:'/finance/jvs',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 3,
              arname: 'أرصدة افتتاحية',
              enname: 'Opening Balance',
              href:'/finance/fient',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 3,
              arname: 'طرق الدفع',
              enname: 'Payment Types',
              href:'/finance/paytypes',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 3,
              arname: 'ربط الدليل',
              enname: 'Integrations',
              href:'/finance/definition',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 3,
              arname: 'الأصول الثابتة',
              enname: 'Fixed Assets',
              href:'/finance/fixed-assets',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 3,
              arname: 'انواع المصروفات',
              enname: 'Expenses Types',
              href:'/settings/expensesTypes',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            {
              index:5,
              main:3,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 3,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 3,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        
        store: {
          main: {
            index: 4,
            arname: 'المخزون',
            enname: 'Inventory',
            href: '/stores',
            to: '/stores',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 4,
              arname: 'المستودعات',
              style: '',
              enname: 'Stores',
              href:'/stores',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'الوحدات',
              style: '',
              enname: 'Units',
              href:'/inventory/units',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تحويل مخزون',
              style: '',
              enname: 'Transfer Inevent',
              href:'/stores-transfer',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: ' الأرصدة الافتتاحية',
              style: '',
              enname: 'Start Inventory',
              href:'/start-Inventory',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 4,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تقرير الجرد',
              style: '',
              enname: 'Inventory Report',
              href:'/inventory-reports',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main: 4,
              arname: 'الاكثر مبيعا',
              enname: 'Most Sales',
              href:'/report/items-max',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 4,
              arname: 'الاقل مبيعا',
              enname: 'Less Sales',
              href:'/report/items-less',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
          ]
        },
        purchase: {
          main: {
            index: 12,
            arname: 'المشتريات',
            enname: 'Inventory',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 12,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'أمر شراء',
              style: '',
              enname: 'Purchase',
              href:'/purchase-order',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'المشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'مرتجع المشتريات',
              style: '',
              enname: 'purchase Return',
              href:'/purchaseReturn',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },

            {
              index:4,
              main: 12,
              arname: 'تقرير المشتريات',
              style: '',
              enname: 'Purchase Report',
              href:'/purchase-report',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 5,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main:5,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير النسب',
              enname: 'Percentage Report',
              href:'/reports/percent-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير الاجازات',
              enname: 'Vacation Report',
              href:'/reports/vacation_report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'تقرير المشرف',
              enname: 'Supervisor Report',
              href:'/supervisor-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 5,
              arname: 'جهاز البصمة',
              enname: 'Supervisor Report',
              href:'',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            
          ]
        },
        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            
            
            {
              index:3,
              main: 6,
              arname: 'كشف حساب',
              enname: 'Statements',
              href:'/reports/statment-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 6,
              arname: 'ميزان المراجعة',
              enname: 'Trial Balance',
              href:'/reports/balance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'الارباح والخسائر',
              enname: 'PNL Report',
              href:'/reports/bnl-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الميزانية',
              enname: 'Balance Report',
              href:'/reports/acbalance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'تقرير الدليل',
              enname: 'COA Report',
              href:'/coa-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الدليل بالمجاميع',
              enname: 'COA Totals Report',
              href:'/coa-reports-totals',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 6,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:8,
              main: 6,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            
          ]
        },
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الربط API',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        // training: {
        //   main: {
        //     index: 100,
        //     arname: 'التدريب',
        //     enname: 'training',
        //     href: '',
        //     to: '',
        //     class: '',
        //     smclass: '', //smclass: 'font-small',
        //     groupid: [1,2,3,4,5,6,7,8],
        //     bgcolor: '#000000',
        //     color: '#ffffff',
        //     frame: 'iframe',
        //     border: '#000',
        //     perms: ['any']
        //   },
        //   sub: []
        // },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      small:{ 
        dashboard: {
          main: {
            index: 1,
            arname: 'الادارة',
            enname: 'Management',
            href: '/ecar',
            to: '/ecar',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 1,
              arname: 'تقارير بيانية',
              enname: 'Management',
              href:'/ecar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التعميدات',
              enname: 'Management',
              href:'/dashboard33',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'الاصول الثابتة',
              enname: 'Fixed Assets',
              href:'/management/fixed-assets',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير اليومية',
              enname: 'Daily Report',
              href:'/reports/income-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'المشتريات',
              enname: 'Purchase',
              href:'/mg-purchase-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الاداء',
              enname: 'KPI Report',
              href:'/reports/kpi-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'عروض تسويقية',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'العقود',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التحصيل',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            
            
          ]
        },
        customers: {
          main: {
            index: 88,
            arname: 'المبيعات',
            enname: 'Customers',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 88,
              arname: 'العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 88,
              arname: 'عروض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:10,
              main: 88,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:10,
              main: 88,
              arname: 'عقود صيانة',
              enname: 'Maint. Contract',
              href:'/maintContracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
            {
              index:6,
              main: 88,
              arname: 'الفحص',
              enname: 'Check Car',
              href:'',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
          ]
        },
        workJob: {
          main: {
            index: 99,
            arname: 'الصيانة',
            enname: 'Works',
            href: '/home',
            to: '/home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:10,
              main: 99,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            {
              index:2,
              main: 99,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 99,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:5,
              main: 99,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2,3,4,5,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'الفواتير',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/invoicelist',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 2,
              arname: 'فاتورة مبيعات',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:4,
              main: 2,
              arname: 'فاتورة مشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main:2,
              arname: 'فاتورة مصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'التحصيل',
              enname: 'Renewal',
              href:'/invoices-not-paid',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'نقاط البيع',
              enname: 'POS',
              href:'/POS',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
          ]
        },
        store: {
          main: {
            index: 4,
            arname: 'المخزون',
            enname: 'Inventory',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 4,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 4,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تقرير الجرد',
              style: '',
              enname: 'Inventory Report',
              href:'/inventory-reports',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['anyxxx']
            },
          ]
        },
        employees: {
          main: {
            index: 5,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main:5,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'تقرير المشرف',
              enname: 'Supervisor Report',
              href:'/supervisor-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['carsddd']
            },
            
          ]
        },
        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: 'تقرير اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'الاكثر مبيعا',
              enname: 'Most Sales',
              href:'/report/items-max',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['carddds']
            },
            {
              index:7,
              main: 6,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:8,
              main: 6,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:9,
              main: 6,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 6,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الربط API',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        
        training: {
          main: {
            index: 100,
            arname: 'التدريب',
            enname: 'training',
            href: '',
            to: '',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff',
            frame: 'iframe',
            border: '#000',
            perms: ['any']
          },
          sub: []
        },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      full: {
        sales: {
          main: {
            index: 1,
            arname: 'المبيعات',
            enname: 'Sales',
            href: '/home',
            to: '/invoicelist',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 1,
              arname: 'بطاقات الصيانة',
              enname: 'Maint. Cards',
              href:'/home',
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 1,
              arname: 'الفواتير',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 1,
              arname: 'العملاء',
              enname: 'Customers',
              href:'/clientList',
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            
            {
              index:4,
              main: 1,
              arname: 'مراجعة العملاء',
              enname: 'Client Review',
              href:'/billClients',
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 1,
              arname: 'عروض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 1,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 1,
              arname: 'التجديد',
              enname: 'Renew',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
          ]
        },
        Accountants: {
          main: {
            index: 2,
            arname: 'المحاسبة',
            enname: 'Accountants',
            href: '/finance/coa',
            to: '/finance/coa',
            class: 'd',
            // smclass: '', //smclass: 'font-small',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 2,
              arname: 'شجرة الحسابات',
              enname: 'Chart Of Account',
              href:'/finance/coa',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 2,
              arname: 'القيود',
              enname: 'JVs',
              href:'/finance/jvs',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'أرصدة افتتاحية',
              enname: 'Opening Balance',
              href:'/finance/fient',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 2,
              arname: 'طرق الدفع',
              enname: 'Payment Types',
              href:'/finance/paytypes',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 2,
              arname: 'الأصول الثابتة',
              enname: 'Fixed Assets',
              href:'/finance/fixed-assets',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 2,
              arname: 'ربط الدليل',
              enname: 'Integrations',
              href:'/finance/definition',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 2,
              arname: 'ملخص المصاريف',
              enname: 'Expenses Summary',
              href:'/expenses/summary',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:9,
              main: 2,
              arname: 'مصروفات عامة',
              enname: 'General Expenses',
              href:'/expenses/general',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:10,
              main: 2,
              arname: 'مصروفات ادارية',
              enname: 'Manage. Expenses',
              href:'/expenses/management',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:11,
              main: 2,
              arname: 'مصروفات دورية',
              enname: 'Recuring Expenses',
              href:'/expenses/recurring',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
          ]
        },
        store: {
          main: {
            index: 3,
            arname: 'المخزون',
            enname: 'Store',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: 'd',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 2,
              arname: 'الموردين',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 2,
              arname: 'المشتريات',
              enname: 'Purchase',
              href:'/inventory/purchase',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'أقسام المخزون',
              enname: 'Prod. Cats',
              href:'/inventory/products-cats',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 2,
              arname: 'المنتجات',
              enname: 'Products',
              href:'/inventory/products',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 2,
              arname: 'الأكثر مبيعا',
              enname: 'Most Sales',
              href:'/inventory/best-seller',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 2,
              arname: 'الأقل مبيعا',
              enname: 'Less Sales',
              href:'/inventory/lowest-selling',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 2,
              arname: 'المنتهية',
              enname: 'Finished',
              href:'/inventory/finished',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 2,
              arname: 'حرجة صنف',
              enname: 'Items Records',
              href:'/inventory/itemtrans',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 4,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 4,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 4,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 4,
              arname: 'تقرير التأمينات',
              enname: 'GOZI Report',
              href:'/emps/gozi-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
          ]
        },
        reports: {
          main: {
            index: 5,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/vat-reports',
            to: '/reports/vat-reports',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير الدخل',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 5,
              arname: 'كشف حساب',
              enname: 'Statements',
              href:'/reports/statment-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 5,
              arname: 'ميزان المراجعة',
              enname: 'Trial Balance',
              href:'/reports/balance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'الارباح والخسائر',
              enname: 'PNL Report',
              href:'/reports/bnl-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 5,
              arname: 'الميزانية',
              enname: 'Balance Report',
              href:'/reports/acbalance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 5,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 5,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:9,
              main: 5,
              arname: 'سندات القبض',
              enname: 'Income Voucher',
              href:'/finance/inreceipt',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:10,
              main: 5,
              arname: 'سندات الصرف',
              enname: 'Out Voucher',
              href:'/finance/outreceipt',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            
          ]
        },
        settings: {
          main: {
            index: 6,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 6,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              groupid:[1],
              perms: ['any']
            },
            {
              index:4,
              main: 6,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/settings/employees',
              class:'mytab4',
              groupid:[1,2],
              style: '',
              perms: ['onlySalesPurchase']
            },
            // {
            //   index:5,
            //   main: 6,
            //   arname: 'انواع المصروفات',
            //   enname: 'Expenses Types',
            //   href:'/settings/expensesTypes',
            //   class:'mytab4',
            //   style: '',
            //   groupid:[1,2],
            //   perms: ['onlySalesPurchase']
            // },
            {
              index:6,
              main: 6,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/employees',
              class:'mytab4',
              groupid:[1,2],
              style: '',
              perms: ['onlySalesPurchase']
            },
          ]
        }
      }
    },
    caneditinvoicedate: true,
    settings: [],
    connected: false,
    products: [],
    smsTemp:[],
    hometitle: 'الفواتير',
    hometitle_en: 'Invoices',
    carsys: false,
    debug:0,
    licenseType: {
      manafactoring_enable: false,
      show_jobcard: false,
      sam_project: false,
      car_contract: false,
      samcrm: false,
      showIDNumber: false,
      erp: false,
      showOwnerName: false,
      showCarPlate: false,
      perDiscount: false,
      showPlate: false,
      requirePurchase: false,
      showSketch: false,
      showCardNums: false,
      startYear: 2021,
      activemenu: '',
      otherproducts: false,
      cars: false,
      carInfoRequired: false,
      parts: false,
      bill: false,
      quotation: false,
      clientStatment:false,
      carinfo: false,
      salarysys: false,
      showproject: false,
      crm: false,
      showAddedBy: false,
      printDelNotes: false,
      agentPurchase: false,
      expenses: false,
      POSREST: false,
      POSRET: false,
      families: false,
      fullversion:false,
      orderlic: false,
      onlySalesPurchase: true,
      isBill: false,
      isSupport: false,
      isCar: false,
      showInvoiceList: false,
      showMaintCart:false,
      showItemCode: false,
      require_itemcode: false,
      moneyComma: false,
      showExtra: false,
      demoVersion: false,
    },
    viewhomeaddinvo:false,
    zeroPrice:true,
    SystemName: 'SAMCOTEC',
    Version: "ERP - 3.3.01", 
    // Version: "ECAR - 3.2.10", 
    deflang: 'ar',
    
    group_id: '',

    headerIcone: 1
  },
  getters:{
    state: (state) => state
  },
  mutations: {
    setLicenseType: function(state,val) {
      Object.keys(val).forEach(key => {
        if(key != 'activemenu'){
          state.licenseType[key] = true;
        }
        else state.licenseType[key] = val[key];
      });
    }
  },
  actions: {

  },
  modules: {
    billStore,
    inOutReport,
    customerSupport
  }
})
